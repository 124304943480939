import React, { useEffect, useState } from "react";
import {
  AddIcon,
  ButtonRefactored as Button,
  InputSelect,
  InputText,
  Modal,
  Stack,
  HeadingDetailed,
  Filter, InputCheckboxGroup, InputCheckbox
} from "@operata/adagio";
import {Condition, DataRole, EmbeddedDashboard} from "../../models/dataRole";

type EditConditionsModalProps = {
  playbooks: EmbeddedDashboard[],
  onClose: () => void;
  onSave: (role: DataRole) => void;
  role: DataRole;
}

type DashboardAccessProps = {
  playbooks: EmbeddedDashboard[],
  role: DataRole;
  onDashboardAccessChange: (role: DataRole) => void;
}

type FieldOption = {
  type: "group1" | "group2" | "group3" | "group4" | "group5" | "attributes";
  name: string;
  fieldType: string;
}

const Operators: string[] = ["equals", "startsWith"];

const options: FieldOption[] = [
  { type: "group1", name: "Agent Group 1", fieldType: "column" },
  { type: "group2", name: "Agent Group 2", fieldType: "column"},
  { type: "group3", name: "Agent Group 3", fieldType: "column" },
  { type: "group4", name: "Agent Group 4", fieldType: "column" },
  { type: "group5", name: "Agent Group 5", fieldType: "column" },
  { type: "attributes", name: "CTR Attributes", fieldType: "json" },
];

export default function EditTeamModal({ playbooks, role, onClose, onSave }: EditConditionsModalProps) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [name, setName] = useState(role.name);
  const [conditions, setConditions] = useState<Condition[]>(role.conditions || []);
  const [dashboards, setDashboards] = useState<number[]>(role.dashboards || []);

  const handleUpdate = (condition: Condition, index: number) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setIsModified(true);
    setConditions(newConditions);
  }

  const handleRemove = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setIsModified(true);
    setConditions(newConditions);
  }

  const addNewCondition = () => {
    const availableOptions = getAvailableOptions({} as Condition, conditions, options);
    if (availableOptions.length === 0) {
      return;
    }

    const newCondition: Condition = {
      type: availableOptions[0].type,
      operator: "",
      field: "",
      value: ""
    };
    setIsModified(true);
    setConditions([...conditions, newCondition]);
  }

  useEffect(() => {
    const getKey = (condition: Condition) => `${condition.type}-${condition.field}`;
    const hasDuplicate = conditions.length !== new Set(conditions.map(getKey)).size;
    const isValid = conditions.every(c => {
      const option = options.find(o => o.type === c.type);
      if (!option) {
        return false;
      }

      if (option.fieldType === "json") {
        return c.field && c.value;
      }

      return c.value;
    });
    setIsFormValid(!hasDuplicate && isValid);

  }, [conditions]);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    setIsModified(true);
  }

  const handleDashboardAccessChange = (updatedRole: DataRole) => {
    setIsModified(true);
    setDashboards(updatedRole.dashboards || []);
  }

  const handleSave = () => {
    const updatedDashboards = dashboards.length === 0 ? playbooks.map(pb => pb.id) : dashboards;
    onSave({ ...role, name, conditions, dashboards: updatedDashboards });
  }

  return (
     <div>
       <Modal
          isOpen={true}
          onClose={onClose}
          width="medium"
       >
         <Modal.Header border>Edit Team - {role.name}</Modal.Header>
         <Modal.Content>
           <Stack direction="column" gap="small" padding="medium" block>
             <InputText
                width="100%"
                label={"Team Name"}
                value={name}
                placeholder={""}
                onChange={handleNameChange}
             />
             <Stack.Divider />
             <HeadingDetailed heading={"Filter"} />
             <Filter>
               {conditions.length > 0 ?
                  conditions?.map((condition, index) => (
                     <TeamFilter
                        key={index}
                        fieldOptions={getAvailableOptions(condition, conditions, options)}
                        condition={condition}
                        onUpdate={(condition) => handleUpdate(condition, index)}
                        onRemove={() => handleRemove(index)}
                        index={index}></TeamFilter>
                  )) :
                  <div>no filters</div>
               }
             </Filter>
             <Filter.Add>
               <Button type="secondary" icon={<AddIcon />} onClick={addNewCondition}>Add Condition</Button>
             </Filter.Add>
             <Stack.Divider />
             <DashboardAccess playbooks={playbooks} role={{ ...role, dashboards }} onDashboardAccessChange={handleDashboardAccessChange} />
           </Stack>
         </Modal.Content>
         <Modal.Footer background>
           <Stack direction="row" gap="8" justify="end">
             <Button onClick={onClose} type="tertiary" size="large">
               Cancel
             </Button>
             <Button
                disabled={!isFormValid || !isModified}
                onClick={() => onSave({ ...role, name, conditions, dashboards })}
                type="primary"
                size="large"
             >
               Save
             </Button>
           </Stack>
         </Modal.Footer>
       </Modal>
     </div>
  );
}

function getAvailableOptions(currentCondition: Condition, conditions: Condition[], fieldOptions: FieldOption[]) {
  return fieldOptions.filter((option) => {
    const isCurrentCondition = currentCondition.type === option.type;
    const isExistingCondition = conditions.map((condition) => condition.type).includes(option.type);
    const isJsonField = option.fieldType === "json";

    // can have multiple json fields
    return isCurrentCondition || (isJsonField || !isExistingCondition);
  });
}

type FieldConditionProps = {
  condition: Condition;
  fieldOptions: FieldOption[];
  index: number;
  onUpdate: (condition: Condition) => void;
  onRemove: () => void;
}

function TeamFilter({ condition, fieldOptions, index, onUpdate, onRemove }: FieldConditionProps) {
  const currentOption = fieldOptions.find((option) => option.type === condition.type);
  const handleSelectChange = (value: string) => {
    onUpdate({
      ...condition,
      type: value
    });
  }

  const handleOperatorChange = (value: string) => {
    onUpdate({
      ...condition,
      operator: value
    });
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...condition,
      field: e.target.value
    });
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...condition,
      value: e.target.value
    });
  }
  return (
     <Filter.Row
        onClick={onRemove}
        label={index === 0 ? "Where" : "And"}
        inputType={<InputSelect selectedValue={condition.type} setSelectedValue={handleSelectChange}>
          {fieldOptions.map((option, index) => (
             <InputSelect.Option key={index} value={option.type}>{option.name}</InputSelect.Option>
          ))}
        </InputSelect>}
        field={currentOption?.fieldType === "json" && (
           <InputText value={condition.field} placeholder={"field"} onChange={handleFieldChange} />
        )}
        operator={<InputSelect selectedValue={condition.operator} setSelectedValue={handleOperatorChange}>
          {Operators.map((operator, index) => (
             <InputSelect.Option key={index} value={operator}>{operator}</InputSelect.Option>
          ))}
        </InputSelect>}
        inputValue={<InputText value={condition.value} placeholder={"has value"} onChange={handleValueChange} />}
     />
  );
}

function DashboardAccess({ playbooks, role, onDashboardAccessChange }: DashboardAccessProps) {
  const [allDashboardsChecked, setAllDashboardsChecked] = useState(!role.dashboards || role.dashboards.length === 0);

  const handleCheckboxChange = (playbookId: number) => {
    if (playbookId === -1) {
      setAllDashboardsChecked(!allDashboardsChecked);
      const updatedRole = { ...role, dashboards: !allDashboardsChecked ? [] : role.dashboards };
      onDashboardAccessChange(updatedRole);
    } else {
      const updatedDashboards = role.dashboards?.includes(playbookId)
         ? role.dashboards.filter(id => id !== playbookId)
         : [...(role.dashboards || []), playbookId];
      const updatedRole = { ...role, dashboards: updatedDashboards };
      onDashboardAccessChange(updatedRole);
    }
  };

  return (
     <Stack direction={"column"} gap={"small"} block>
       <HeadingDetailed level={4} heading={"Dashboard Access"} />
       <InputCheckboxGroup>
         <InputCheckbox
            key={"all-dashboards"}
            checked={allDashboardsChecked}
            onChange={() => handleCheckboxChange(-1)}
         >
           All Dashboards
         </InputCheckbox>
         {playbooks.map(playbook => (
            <InputCheckbox
               key={playbook.id}
               checked={role.dashboards?.includes(playbook.id)}
               onChange={() => handleCheckboxChange(playbook.id)}
               disabled={allDashboardsChecked}
            >
              {playbook.name}
            </InputCheckbox>
         ))}
       </InputCheckboxGroup>
     </Stack>
  );
}
