import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "./Group.scss";
import {
  fetchGroups,
  updateCanvas,
  CANVAS_ENABLED,
  ERROR_CANVAS_ENABLE,
} from "../../actions/groups";
import {
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
  showSnackbarMessage,
} from "../../actions/snackbar";

import {
  ButtonRefactored,
  Stack,
  Table,
  InputSearch,
  InputSelect,
  TableFilter,
  BrickCopy,
  Skeleton,
  TablePagination,
} from "@operata/adagio";

import { Paginate } from "../../utils/paginate";
import { showConfirmationDialog } from "../../actions/dialog";
import { createCSVDownload } from "../Datatables/utils";
import { sortData } from "../../utils/datatables";
import { GroupState, Group } from "../../reducers/groups";
import styled from "@emotion/styled";

interface Column {
  name: string;
  download: boolean;
}

interface DownloadOptions {
  filename: string;
  separator: string;
}

interface CSVOptions {
  downloadOptions: DownloadOptions;
}

type GroupStateSlice = Pick<
  GroupState,
  "data" | "loading" | "message" | "status"
>;

const PaddingTop = styled.div`
  padding-top: 12px;
`;

const downloadCSV = (groups: Group[]): void => {
  const columns: Column[] = [
    {
      name: "Customer",
      download: true,
    },
    {
      name: "Group",
      download: true,
    },
    {
      name: "Region",
      download: true,
    },
    {
      name: "Group Status",
      download: true,
    },
  ];

  const data = groups.map((data) => ({
    data: [data.customerName, data.name, data.region, data.status],
  }));

  const options: CSVOptions = {
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ",",
    },
  };

  createCSVDownload(columns, data, options);
};

interface DispatchProps {
  fetchGroups: (type: string) => void;
  showConfirmationDialog: (
    title: string,
    message: string,
    onConfirm: (...args: any[]) => void,
    confirmArgs: any[],
    onCancel: (...args: any[]) => void,
    cancelArgs: any[]
  ) => void;
  updateCanvas: (groupId: string) => void;
  showSnackbarMessage: (type: string, message?: string) => void;
}

type GroupListProps = GroupStateSlice &
  DispatchProps &
  RouteComponentProps & {
    type: string;
  };

export const GroupList: React.FC<GroupListProps> = ({
  fetchGroups,
  showConfirmationDialog,
  updateCanvas,
  showSnackbarMessage,
  data,
  status,
  message,
  loading,
  history,
  type,
}) => {
  const processedData = data.map((item) => ({
    ...item,
    status: item.status || "Archived",
  }));

  const customers = Array.from(
    new Set(processedData.map((item) => item.customerName))
  ).filter(Boolean);
  const groupNames = Array.from(
    new Set(processedData.map((item) => item.name))
  ).filter(Boolean);
  const statuses = Array.from(
    new Set(processedData.map((item) => item.status))
  ).filter(Boolean);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [customerFilter, setCustomerFilter] = useState<string | null>(null);
  const [groupFilter, setGroupFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortKey, setSortKey] = useState<keyof Group>("customerName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [shouldShowPagination, setShouldShowPagination] =
    useState<boolean>(false);
  const [paginationPage, setPaginationPage] = useState<number>(0);

  const handleSortClick = (key: keyof Group) => {
    if (sortKey === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    fetchGroups(type);
    setSearchTerm("");
    setCustomerFilter(null);
    setGroupFilter(null);
    setStatusFilter(null);
    setPage(0);
  }, [fetchGroups, type]);

  useEffect(() => {
    if (status === CANVAS_ENABLED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, message);
    } else if (status === ERROR_CANVAS_ENABLE) {
      showSnackbarMessage(SNACKBAR_ERROR, message);
    }
  }, [status, message, showSnackbarMessage]);

  useEffect(() => {
    if (
      data.length > 0 &&
      !loading &&
      status === "GROUPS_RECEIVED" &&
      pageSize > 0
    ) {
      setShouldShowPagination(true);
    } else {
      setShouldShowPagination(false);
    }
  }, [data.length, loading, page, pageSize, status]);

  useEffect(() => {
    if (shouldShowPagination) {
      setPaginationPage(1);
    }
  }, [shouldShowPagination]);

  let filteredGroups = processedData;

  if (searchTerm) {
    filteredGroups = filteredGroups.filter(
      (item) =>
        item?.customerName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.customerID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.id?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  if (customerFilter) {
    filteredGroups = filteredGroups.filter(
      (item) => item.customerName === customerFilter
    );
  }
  if (groupFilter) {
    filteredGroups = filteredGroups.filter((item) => item.name === groupFilter);
  }
  if (statusFilter) {
    filteredGroups = filteredGroups.filter(
      (item) => item.status === statusFilter
    );
  }

  const sortedGroups = filteredGroups.sort(sortData(sortKey, sortOrder));
  const paginatedGroups = Paginate({ pageSize, page: paginationPage })(
    sortedGroups
  );

  const handleUpdateCanvas = (groupId: string, groupName: string): void => {
    const handleCancel = () => {
      console.log("Canvas update canceled");
    };
    showConfirmationDialog(
      "Update Canvas",
      `Are you sure you want to update canvas for group ${groupName}?`,
      updateCanvas,
      [groupId],
      handleCancel,
      []
    );
  };

  return (
    <>
      <TableFilter
        search={
          <InputSearch
            value={searchTerm}
            placeholder="Search Table"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(event.target.value)
            }
            onClear={() => setSearchTerm("")}
          />
        }
        filters={
          <>
            <InputSelect
              placeholder="Customer"
              selectedValue={customerFilter}
              setSelectedValue={(value: string | null) => {
                setCustomerFilter(value);
                setPage(0);
              }}
            >
              {customers.map((customer, index) => (
                <InputSelect.Option key={index} value={customer} />
              ))}
            </InputSelect>
            <InputSelect
              placeholder="Group Name"
              selectedValue={groupFilter}
              setSelectedValue={(value: string | null) => {
                setGroupFilter(value);
                setPage(0);
              }}
            >
              {groupNames.map((group, index) => (
                <InputSelect.Option key={index} value={group} />
              ))}
            </InputSelect>
            <InputSelect
              placeholder="Status"
              selectedValue={statusFilter}
              setSelectedValue={(value: string | null) => {
                setStatusFilter(value);
                setPage(0);
              }}
            >
              {statuses.map((status, index) => (
                <InputSelect.Option key={index} value={status} />
              ))}
            </InputSelect>
            <ButtonRefactored
              size="medium"
              type="secondary"
              disabled={
                !searchTerm && !customerFilter && !groupFilter && !statusFilter
              }
              onClick={() => {
                setSearchTerm("");
                setCustomerFilter(null);
                setGroupFilter(null);
                setStatusFilter(null);
                setPage(0);
              }}
            >
              Reset
            </ButtonRefactored>
          </>
        }
        actions={
          <ButtonRefactored
            type="secondary"
            size="medium"
            disabled={!data.length}
            onClick={() => downloadCSV(data)}
            iconAfter={<Table.DownloadIcon />}
          >
            Download CSV
          </ButtonRefactored>
        }
      />
      <Table>
        <thead>
          <Table.Row>
            <Table.Header
              width="auto"
              sortable
              sortIcon={
                sortKey === "customerName" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("customerName")}
            >
              Customer
            </Table.Header>
            <Table.Header
              width="auto"
              sortable
              sortIcon={
                sortKey === "name" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("name")}
            >
              Group Name
            </Table.Header>
            <Table.Header
              align="center"
              width={250}
              sortable
              sortIcon={
                sortKey === "region" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("region")}
            >
              Region
            </Table.Header>
            <Table.Header
              width={250}
              align="center"
              sortable
              sortIcon={
                sortKey === "status" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("status")}
            >
              Status
            </Table.Header>
            <Table.Header width={0} />
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={16} />
                    <Skeleton loading width={265} height={16} />
                  </Stack>
                </Table.Cell>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={16} />
                    <Skeleton loading width={265} height={16} />
                  </Stack>
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="right">
                  <Stack direction={"column"} gap="4">
                    <Skeleton loading width={50} height={32} />
                    <Skeleton loading width={135} height={32} />
                    <Skeleton loading width={125} height={32} />
                  </Stack>
                </Table.Cell>
              </Table.Row>
            ))
          ) : paginatedGroups.length && shouldShowPagination ? (
            paginatedGroups.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  {item.customerName ? (
                    <BrickCopy
                      title={item.customerName}
                      onClick={() =>
                        navigator.clipboard.writeText(item.customerID)
                      }
                    >
                      {item.customerID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {item.id ? (
                    <BrickCopy
                      title={item.name}
                      onClick={() => navigator.clipboard.writeText(item.id)}
                    >
                      {item.id}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell align="center">{item.region}</Table.Cell>
                <Table.Cell align="center">{item.status}</Table.Cell>
                <Table.Cell align="right">
                  <Stack direction={"column"} gap="4">
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() => history.push(`/admin/group/${item.id}`)}
                    >
                      Edit
                    </ButtonRefactored>
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() =>
                        (window.location.href = `/admin/group/${item.id}/member/0`)
                      }
                    >
                      Invite Teammate
                    </ButtonRefactored>
                    <ButtonRefactored
                      type="tertiary"
                      size="small"
                      onClick={() => handleUpdateCanvas(item.id, item.name)}
                    >
                      Update Canvas
                    </ButtonRefactored>
                  </Stack>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={4}>No data found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      {shouldShowPagination && paginatedGroups.length > 0 && (
        <div data-testid="pagination-info">
          <PaddingTop>
            <TablePagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={paginationPage}
              setPage={setPaginationPage}
              count={filteredGroups.length}
            />
          </PaddingTop>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchGroups,
  showConfirmationDialog,
  updateCanvas,
  showSnackbarMessage,
};

const mapStateToProps = (state: any): GroupStateSlice => ({
  data: state.groups.data,
  status: state.groups.status,
  message: state.groups.message,
  loading: state.progress.loading,
});

interface OwnProps {
  type?: string;
}

export default withRouter(
  connect<GroupStateSlice, DispatchProps, OwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
  )(GroupList)
);
