import {
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const datasourceConfig: ConfigField[] = [
  {
    type: `json`,
    key: `CLOUD_COLLECTOR_VERSION`,
    title: `Cloud Collector`,
    fieldText: `Cloud Collector`,
    description: `Cloud Collector version`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `json`,
    key: `ORCHESTRATOR_VERSION`,
    title: `Orchestrator`,
    fieldText: `Orchestrator`,
    description: `Orchestrator version`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `json`,
    key: `REDACTION_CONFIG`,
    title: `CTR Redaction Config`,
    fieldText: `CTR Redaction Config`,
    description: `The redaction config used to redact CTR data collected`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `array`,
    key: `CTR_ATTRIBUTES_WHITELIST`,
    title: `Allowlisted CTR Attributes`,
    fieldText: `Allowlisted CTR Attributes`,
    description: `The CTR attributes that can be collected`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    default: ``,
  },
];
