import React from "react";
import {
  Badge,
  Table,
  ButtonRefactored as Button,
  DeleteIcon,
  BrickCopy,
  Stack,
} from "@operata/adagio";
import { EventBridgeSource } from "./EventBridge";

type EventBridgeSourceProps = {
  eventSource: EventBridgeSource;
  onDeleteSelect: (eventSource: EventBridgeSource) => void;
};

const EventBridgeStatusBadge = ({ status }: { status: string | null }) => {
  if (!status) {
    return <Badge tone="neutral">UNKNOWN</Badge>;
  }

  if (status.toLowerCase() === "active") {
    return <Badge tone="success">{status.toUpperCase()}</Badge>;
  }

  if (status.toLowerCase() === "deleted") {
    return <Badge tone="danger">{status.toUpperCase()}</Badge>;
  }

  if (status.toLowerCase() === "pending") {
    return (
      <Badge
        tone="info"
        tooltip={{
          label:
            "An event bus is not yet associated with the event source. Please associate.",
          position: "bottom",
        }}
      >
        {status.toUpperCase()}
      </Badge>
    );
  }

  return <Badge tone="neutral">{status.toUpperCase()}</Badge>;
};

const EventBridgeSourceRow = ({
  eventSource,
  onDeleteSelect,
}: EventBridgeSourceProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Table.Text color="primary" nowrap>
          {eventSource.awsRegion}
        </Table.Text>
      </Table.Cell>
      <Table.Cell>
        <Stack direction="row" align="center" justify="start" block>
          <BrickCopy
            onClick={() =>
              navigator.clipboard.writeText(eventSource.eventSourceName)
            }
            mono={false}
          >
            <Table.Text color="secondary" ellipsis>
              {eventSource.eventSourceName}
            </Table.Text>
          </BrickCopy>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Table.Text color="secondary">{eventSource.accountId}</Table.Text>
      </Table.Cell>
      <Table.Cell>
        <EventBridgeStatusBadge status={eventSource.status} />
      </Table.Cell>
      <Table.Cell padding={"medium"}>
        <Button
          onClick={() => onDeleteSelect(eventSource)}
          type="ghost"
          size="small"
          icon={<DeleteIcon />}
          tone="danger"
          tooltip={{
            label: "Delete",
            position: "left",
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EventBridgeSourceRow;
