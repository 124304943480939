import React, { useEffect, useState, useMemo } from "react";
import {
  ButtonRefactored as Button,
  PageHeading,
  Stack,
  Table,
  AddIcon,
  Badge
} from "@operata/adagio";
import CreateRoleModal from "./CreateRoleModal";
import {Condition, CreateDataRole, DataRole, EmbeddedDashboard, UpdateDataRole} from "../../models/dataRole";
import UpdateRoleModal from "./UpdateRoleModal";
import { UserProfile } from "../../models/user";
import { useDispatch } from "react-redux";
import {
  addUserToRole,
  fetchUsersForRole,
  removeUserFromRole,
  updateRole
} from "../../actions/playbooks";
import EditTeamModal from "./EditTeamModal";
import {sortData} from "../../utils/datatables";

type RolesProps = {
  playbooks: EmbeddedDashboard[],
  roles: DataRole[],
  users: UserProfile[],
  onCreate: (role: CreateDataRole) => void,
  usersForRole?: Record<number, string[]>,
  onUpdate: (role: UpdateDataRole) => void
};

function Roles({playbooks, roles, users, usersForRole, onCreate, onUpdate}: RolesProps) {
  const [showCreate, setShowCreate] = useState(false);
  const [editUsersForRole, setEditUsersForRole] = useState<DataRole | null>(null);
  const [editConditionsForRole, setEditConditionsForRole] = useState<DataRole | null>(null);
  const [sortKey, setSortKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const dispatch = useDispatch();

  const handleSortClick = (key: string) => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const handleCreateRole = (role: CreateDataRole) => {
    setShowCreate(false);
    onCreate(role);
  }

  const handleUpdateRole = (role: UpdateDataRole) => {
    if (!editConditionsForRole) return;
    onUpdate(role);
    setEditConditionsForRole(null);
  }

  const handleAddUser = (user: UserProfile) => {
    if (editUsersForRole) {
      dispatch(addUserToRole(editUsersForRole, user.id));
    }
  }

  const handleRemoveUser = (user: UserProfile) => {
    if (editUsersForRole) {
      dispatch(removeUserFromRole(editUsersForRole, user.id));
    }
  }

  useEffect(() => {
    if (editUsersForRole) {
      dispatch(fetchUsersForRole(editUsersForRole));
    }
  }, [dispatch, editUsersForRole]);

  const sortedRoles = useMemo(() => {
    return roles.sort(sortData(sortKey, sortOrder));
  }, [roles, sortKey, sortOrder]);

  return (
    <>
      {showCreate && <CreateRoleModal onSave={handleCreateRole} onClose={() => setShowCreate(false)}/>}
      {editUsersForRole && <UpdateRoleModal
          role={editUsersForRole}
          users={users}
          usersForRole={usersForRole?.[editUsersForRole.id] || []}
          onAddUser={handleAddUser}
          onRemoveUser={handleRemoveUser}
          onClose={() => setEditUsersForRole(null)}/>}
      {editConditionsForRole && <EditTeamModal
          playbooks={playbooks}
          role={editConditionsForRole}
          onClose={() => setEditConditionsForRole(null)}
          onSave={handleUpdateRole}
        />
      }
      <PageHeading title={`Teams`}>
        <Button
          onClick={() => setShowCreate(true)}
          type="primary"
          size="large"
          iconAfter={<AddIcon/>}
        >
          New Role
        </Button>
      </PageHeading>
      <Stack padding={"small"}/>
      <Table>
        <thead>
        <Table.Row>
          <Table.Header
             width="auto"
             sortable
             sortIcon={
               sortKey === "name" ? (
                  sortOrder === "desc" ? (
                     <Table.SortDescendingIcon/>
                  ) : (
                     <Table.SortAscendingIcon/>
                  )
               ) : null
             }
             onSortClick={() => handleSortClick("name")}>
            Team Name
          </Table.Header>
          <Table.Header width="auto">Data Conditions</Table.Header>
          <Table.Header width="200" align="left"></Table.Header>
        </Table.Row>
        </thead>
        <tbody>
        {sortedRoles?.map((role, index) => (
          <Table.Row key={index}>
            <Table.Cell align={"center"}>
              <Stack direction={"row"} gap={"4"} align={"center"}>
                {role.name}
              </Stack>
            </Table.Cell>
            <Table.Cell>
              <Stack direction="row" wrap gap="4">
                {role.conditions?.map((condition, index) => (
                  <Conditions key={index} condition={condition}/>
                ))}
              </Stack>
            </Table.Cell>
            <Table.Cell align="right">
              <Stack direction="row" gap="4" justify={"end"}>
                <Button
                  type="tertiary"
                  size="small"
                  onClick={() => setEditUsersForRole(role)}
                >
                  View Members
                </Button>
                <Button
                  type="tertiary"
                  size="small"
                  onClick={() => setEditConditionsForRole(role)}
                >
                  Edit
                </Button>
              </Stack>
            </Table.Cell>
          </Table.Row>
        ))}
        </tbody>
      </Table>
    </>
  );
}

type ConditionProps = {
  condition: Condition;
}

function Conditions({condition}: ConditionProps) {
  return (
    <Badge tone={"info"}>
      {condition.type}{condition.field ? `.${condition.field}` : ``}: {condition.value}
    </Badge>
  );
}

export default Roles;
