import React, { Component } from "react";
import vegaEmbed from "vega-embed";
import { connect } from "react-redux";

class CampaignViewStatsOverallGraph extends Component {
  componentDidMount() {
    this.showGraph();
  }

  componentDidUpdate() {
    this.showGraph();
  }

  showGraph() {
    let spec = this._spec();
    spec.width = this.props.windowWidth - 40;
    vegaEmbed(this.refs.xx, spec, {
      tooltip: { theme: "dark" },
      actions: false,
    })
      .then(function () {})
      .catch(console.error);
  }

  render() {
    return <div ref="xx" />;
  }

  _spec() {
    const retJson = {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      width: "container",
      data: {
        values: this.props.summaryStats,
      },
      layer: [
        {
          mark: "bar",
          encoding: {
            x: {
              round: "true",
              aggregate: "sum",
              field: "calls",
              type: "quantitative",
              title: "Calls Summary",
              axis: {
                tickCount: 10,
              },
            },
            y: { field: "variety", type: "nominal", title: "" },
            color: {
              field: "status",
              type: "nominal",
              title: "Call Statuses",
              scale: {
                domain: ["Successful", "Failed", "Pending"],
                range: ["green", "red", "grey"],
              },
            },
            tooltip: { field: "calls", type: "quantitative" },
          },
        },
      ],
    };

    return retJson;
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
});

const ConnectedCampaignViewStatsOverallGraph = connect(
  mapStateToProps,
  null
)(CampaignViewStatsOverallGraph);

export default ConnectedCampaignViewStatsOverallGraph;
