import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  downloadLogsFetched,
  fetchAgentCCPLogs,
  getAgentCCPLogs,
  showProgress,
} from "../../actions/agent";
import {
  ButtonRefactored as Button,
  Skeleton,
  Table,
  PageFilter,
  HistoryIcon,
  Chip,
  InputSelect,
  InputText,
  FilterIcon,
  TablePagination,
} from "@operata/adagio";
import DateTimePicker from "../DateTime/Wrapper";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import moment from "moment-timezone";
import { sortData } from "../../utils/datatables";
import { AgentLog } from "../../models/agent";
import { Filter, SearchCriteria, RangeKey } from "../../models/dataTable";
import { RootState } from "../../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";

export const getSearchCriteria = (
  timeRange: string[],
  filters: Filter[],
  page: number,
  pageSize: number,
  sortKey?: string,
  sortOrder?: string
): SearchCriteria => {
  timeRange = timeRange || ["Last 4 hours", "now-4h", "now"];
  const [, fromTime, toTime] = timeRange;

  const criteria: SearchCriteria = {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
    size: Number(pageSize),
    from: Number(page * pageSize),
  };

  filters.forEach(({ field, value }) => {
    if (!(field in criteria)) {
      const numValue = Number(value);
      (criteria as any)[field] = !isNaN(numValue) ? numValue : value;
    }
  });

  if (sortKey && sortOrder) {
    (criteria as any)["sortColumn"] = sortKey;
    (criteria as any)["sortDirection"] = sortOrder;
  }

  return criteria;
};

interface AgentLogsProps extends RouteComponentProps {
  fetchAgentCCPLogs: (criteria: SearchCriteria) => void;
  logs: AgentLog[];
  recordCount: number;
  loading: boolean;
  status?: string;
}

const AgentLogs = ({ logs, recordCount, loading, status }: AgentLogsProps) => {
  const fields = ["agent", "level"];
  const operators = ["="];

  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [timeRange, setTimeRange] = useState<string[]>([
    "Last 4 hours",
    "now-4h",
    "now",
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortKey, setSortKey] = useState("time");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedField, setSelectedField] = useState<string>(fields[0]);
  const [selectedOperator, setSelectedOperator] = useState<string>(
    operators[0]
  );
  const [value, setValue] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number>(0);
  const [selectedRange, setSelectedRange] = useState("Last 4 hours");
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [paginationPage, setPaginationPage] = useState<number>(1);

  const isSaveDisabled = !selectedField || !selectedOperator || !value;

  const handleEditFilter = (index: number) => {
    const filterToEdit = activeFilters[index];
    setSelectedField(filterToEdit.field);
    setSelectedOperator(filterToEdit.operator);
    setValue(filterToEdit.value);
    setIsFilterOpen(true);
    setIsEditing(true);
    setEditingIndex(index);
  };

  function resetFilters() {
    setActiveFilters([]);
    setPage(0);
    setSelectedRange("Last 4 hours");
    setTimeRange(["Last 4 hours", "now-4h", "now"]);
  }

  const handleNewFilterClick = () => {
    resetForm();
    setIsFilterOpen(true);
  };

  const resetForm = () => {
    setSelectedField(fields[0]);
    setSelectedOperator(operators[0]);
    setValue("");
    setIsFilterOpen(false);
    setIsEditing(false);
    setEditingIndex(0);
  };

  const handleAddFilter = () => {
    const newFilter = {
      field: selectedField,
      operator: selectedOperator,
      value: value,
    };

    if (isEditing && editingIndex !== null) {
      const updatedFilters = [...activeFilters];
      updatedFilters[editingIndex] = newFilter;
      setActiveFilters(updatedFilters);
    } else {
      setActiveFilters([...activeFilters, newFilter]);
    }

    setPage(0);
    resetForm();
  };

  const handleSortClick = (key: string) => {
    if (sortKey === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("desc");
    }
  };

  const handleDeleteFilter = (indexToDelete: number, event: any) => {
    event.stopPropagation();
    setActiveFilters(
      activeFilters.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleLogDownload = async () => {
    dispatch(showProgress());

    const logs = [];
    const page = 0;
    const batchSize = 1000;
    const results = await getAgentCCPLogs(
      getSearchCriteria(timeRange, activeFilters, page, batchSize)
    );
    logs.push(...results.data);

    createJSONDownload(logs);

    dispatch(downloadLogsFetched());
  };

  const createJSONDownload = (logs: AgentLog[]) => {
    const blob = new Blob([JSON.stringify(logs)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "agent-logs.json";
    a.click();
    a.remove();
  };

  useEffect(() => {
    dispatch(
      fetchAgentCCPLogs(
        getSearchCriteria(
          timeRange,
          activeFilters,
          Math.max(0, paginationPage - 1),
          pageSize,
          sortKey,
          sortOrder
        )
      )
    );
  }, [
    dispatch,
    timeRange,
    paginationPage,
    pageSize,
    activeFilters,
    sortKey,
    sortOrder,
  ]);

  useEffect(() => {
    if (!loading && status === "AGENT_CCP_LOGS_RECEIVED") {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
  }, [loading, status]);

  const sortedLogs = logs.sort(sortData(sortKey, sortOrder));

  return (
    <>
      <PageFilter
        onNewFilterButtonClick={handleNewFilterClick}
        primary={
          <>
            <div
              style={{
                position: "absolute",
                marginTop: "5px",
              }}
            >
              <DateTimePicker
                rightAlign={false}
                dateRangeSelected={(
                  range: RangeKey,
                  start: string,
                  end: string
                ) => {
                  if (range === "Custom Range") {
                    setTimeRange([range, start, end]);
                  } else {
                    const timeRange = getRanges(new Date())[range];
                    setTimeRange([
                      String(range),
                      String(timeRange[4]),
                      String(timeRange[5]),
                    ]);
                  }
                  setSelectedRange(range);
                  setPickerOpen(false);
                }}
                pickerOpen={pickerOpen ? "open" : ""}
                setPickerOpen={(pickerOpen: boolean) =>
                  setPickerOpen(!!pickerOpen)
                }
                currentRange={null}
                currentStartTime={timeRange?.[1]}
                currentEndTime={timeRange?.[2]}
                selectedRange={selectedRange}
              />
            </div>
            <Button
              type="tertiary"
              icon={<HistoryIcon />}
              onClick={setPickerOpen.bind(null, !pickerOpen)}
            >
              {selectedRange}
            </Button>
          </>
        }
        filters={
          <>
            <PageFilter.FilterCreate
              selectedField={selectedField}
              setSelectedField={setSelectedField}
              selectedOperator={selectedOperator}
              setSelectedOperator={setSelectedOperator}
              value={value}
              onCancel={() => setIsFilterOpen(false)}
              setValue={setValue}
              onApply={handleAddFilter}
              isOpen={isFilterOpen}
              isEditing={isEditing}
              isSaveDisabled={isSaveDisabled}
              onNewFilterButtonClick={handleNewFilterClick}
              trigger={
                <Button type="tertiary" size="medium" icon={<FilterIcon />}>
                  Filters
                </Button>
              }
              inputSelectField={
                <InputSelect
                  label="Field"
                  selectedValue={selectedField}
                  setSelectedValue={setSelectedField}
                >
                  {fields.map((field) => (
                    <InputSelect.Option key={field}>{field}</InputSelect.Option>
                  ))}
                </InputSelect>
              }
              inputSelectOperator={
                <InputSelect
                  width={120}
                  label="Operator"
                  selectedValue={selectedOperator}
                  setSelectedValue={setSelectedOperator}
                >
                  {operators.map((operator) => (
                    <InputSelect.Option key={operator}>
                      {operator}
                    </InputSelect.Option>
                  ))}
                </InputSelect>
              }
              inputTextValue={
                <InputText
                  label="Value"
                  value={value}
                  onChange={(e: any) => setValue(e.target.value)}
                />
              }
            />
            <>
              {activeFilters &&
                activeFilters.map((filter, index) => (
                  <Chip
                    key={index}
                    onClick={() => handleEditFilter(index)}
                    onDelete={(event: any) => handleDeleteFilter(index, event)}
                  >
                    {`${filter.field} ${filter.operator} ${filter.value}`}
                  </Chip>
                ))}
            </>
            <Button
              size="medium"
              type="secondary"
              disabled={activeFilters.length === 0}
              onClick={() => {
                resetFilters();
              }}
            >
              Clear all
            </Button>
          </>
        }
        actions={
          <Button
            type="secondary"
            size="medium"
            disabled={!logs.length}
            iconAfter={<Table.DownloadIcon />}
            onClick={handleLogDownload}
          >
            Download JSON
          </Button>
        }
      />

      <Table>
        <thead>
          <Table.Row>
            <Table.Header
              width={240}
              align="left"
              sortable
              sortIcon={
                sortKey === "time" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("time")}
            >
              Timestamp
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "agent" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("agent")}
            >
              Agent
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "level" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon />
                  ) : (
                    <Table.SortAscendingIcon />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("level")}
            >
              Log Level
            </Table.Header>
            <Table.Header width="auto">Text</Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : sortedLogs && sortedLogs.length > 0 && showPagination ? (
            sortedLogs.map((log, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Table.Text color="tertiary">
                    <div>
                      {log.time ? (
                        moment(log.time).format("DD-MM-YYYY")
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                    <div>
                      {log.time ? (
                        moment(log.time).format("HH:mm:ss")
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  </Table.Text>
                </Table.Cell>
                <Table.Cell>
                  <Table.Text color="primary">{log.agent}</Table.Text>
                </Table.Cell>
                <Table.Cell>
                  <Table.Text color="tertiary">{log.level}</Table.Text>
                </Table.Cell>
                <Table.Cell>
                  <Table.Text color="tertiary">{log.text}</Table.Text>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            // it will complain about using the wrong colspan here but it is correct
            <Table.RowEmpty colspan={4}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      {showPagination && sortedLogs && sortedLogs.length > 0 && (
        <>
          <TablePagination
            pageSize={pageSize}
            setPageSize={(newSize: number) => {
              setPageSize(newSize);
              setPaginationPage(1);
            }}
            page={paginationPage}
            setPage={setPaginationPage}
            count={recordCount || 0}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchAgentCCPLogs,
};

const mapStateToProps = (state: Pick<RootState, "agent">) => ({
  logs: state.agent.logs || [],
  recordCount: state.agent.recordCount,
  loading: state.agent.loading,
  status: state.agent.status,
});

const connectedAgentLogs = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentLogs);

export default withRouter(connectedAgentLogs);
