import React from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { validate } from "./ActionConfigWebHookValidation";
import { getUrlParts } from "../../Generic/core-validations";
import { OutlinedInput, FormControl, InputLabel } from "@material-ui/core";

class ActionConfigWebHook extends React.Component {
  constructor(props) {
    super();

    this.state = {
      actionId: "",
      type: props.actionType,
      url: "",
      method: "post",
      headers: "",
      body: "",
      displayText: "",
      scheme: "",
      host: "",
      port: "",
      path: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    let { prevState } = this.props;
    if (prevState.type === this.state.type) {
      this.setState(prevState);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.handleAddAction(this.state);
      }
    );
  }

  handleAddAction() {
    let errors = validate(this.state, ["url", "method", "body"], this);
    if (!errors["hasErrors"]) {
      let urlParts = getUrlParts(this.state.url);
      this.setState({
        actionId: "Invoke_Webhook",
        displayText: "[" + this.state.method + "] " + this.state.url,
        scheme: urlParts["protocol"].replace(":", ""),
        host: urlParts["hostname"],
        port: urlParts["port"],
        path: urlParts["pathname"],
        headers: this.state.headers.length === 0 ? "{}" : this.state.headers,
      });
    }

    this.props.handleAddAction(this.state);
    return errors;
  }

  render() {
    return (
      <React.Fragment>
        <div className="form__control">
          <TextField
            error={this.state.error_url}
            label={
              this.state.errorMessage_url ? this.state.errorMessage_url : "Url"
            }
            id="outlined-required"
            name="url"
            className="textField"
            onChange={(event) => this.handleChange(event)}
            variant="outlined"
            value={this.state.url}
          />
        </div>

        <div className="form__control">
          <FormControl variant="outlined">
            <InputLabel htmlFor="method-helper">
              {this.state.errorMessage_method
                ? this.state.errorMessage_method
                : "Method"}
            </InputLabel>
            <Select
              name="method"
              error={this.state.error_method}
              variant="outlined"
              value={
                this.state.method //label={this.state.errorMessage_method ? this.state.errorMessage_method : "Method"}
              }
              onChange={(event) => this.handleChange(event)}
              input={<OutlinedInput name="method" id="method-helper" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"post"}>POST</MenuItem>
              <MenuItem value={"put"}>PUT</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="form__control">
          <TextField
            error={this.state.error_headers}
            label={
              this.state.errorMessage_headers
                ? this.state.errorMessage_headers
                : "Headers"
            }
            id="outlined-required"
            name="headers"
            className="textField"
            onChange={(event) => this.handleChange(event)}
            variant="outlined"
            multiline={true}
            rows="2"
            value={this.state.headers}
          />
        </div>

        <div className="form__control">
          <TextField
            error={this.state.error_body}
            label={
              this.state.errorMessage_body
                ? this.state.errorMessage_body
                : "Body"
            }
            id="outlined-required"
            name="body"
            className="textField"
            onChange={(event) => this.handleChange(event)}
            variant="outlined"
            multiline={true}
            rows="2"
            value={this.state.body}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ActionConfigWebHook;
