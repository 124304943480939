import { SecondarySidebar } from "@operata/adagio";
import React from "react";
import { useRouteMatch } from "react-router-dom";

export interface NavItem {
  path: string;
  label: string;
  configKey?: string;
  exact?: boolean;
}

export interface NavSection {
  heading: string;
  items: NavItem[];
}

const navigationConfig: NavSection[] = [
  {
    heading: "CCASS Config",
    items: [
      {
        path: "",
        label: "Softphone Settings",
        configKey: "SOFTPHONE_MODULE",
        exact: true,
      },
      {
        path: "/genesys",
        label: "Genesys Settings",
        configKey: "GENESYS_MODULE",
      },
      {
        path: "/nice",
        label: "NiCE Settings",
        configKey: "NICE_MODULE",
      },
    ],
  },
  {
    heading: "Group Settings",
    items: [
      {
        path: "/simulate",
        label: "Simulate",
        configKey: "SIMULATE_MODULE",
      },
    ],
  },
  {
    heading: "Integrations",
    items: [
      {
        path: "/api",
        label: "API Management",
        configKey: "API_MODULE",
      },
      {
        path: "/sources",
        label: "AWS Data Sources",
        configKey: "DATA_SOURCES_MODULE",
      },
      {
        path: "/eventbridge",
        label: "EventBridge",
        configKey: "EVENTBRIDGE_MODULE",
      },
    ],
  },
  {
    heading: "Heartbeat",
    items: [
      {
        path: "/heartbeat",
        label: "Heartbeat Config",
        configKey: "HEARTBEAT_MODULE",
      },
      {
        path: "/heartbeat-tagging",
        label: "Heartbeat Tagging",
        configKey: "HEARTBEAT_MODULE",
      },
    ],
  },
  {
    heading: "Extension",
    items: [
      {
        path: "/extension-alerts",
        label: "Alerts",
        configKey: "AGENT_ALERTS_MODULE",
      },
    ],
  },
];

interface ConfigSideBarProps {
  availableConfigs: Array<string>;
}

export const ConfigsSideBar: React.VFC<ConfigSideBarProps> = ({
  availableConfigs,
}) => {
  const { path } = useRouteMatch();

  const isItemVisible = (item: NavItem): boolean => {
    if (!item.configKey) return true;
    return availableConfigs.includes(item.configKey);
  };

  const getSectionVisibleItems = (section: NavSection): NavItem[] => {
    return section.items.filter(isItemVisible);
  };

  return (
    <SecondarySidebar>
      <SecondarySidebar.Scroll>
        {navigationConfig.map((section) => {
          const visibleItems = getSectionVisibleItems(section);

          return visibleItems.length > 0 ? (
            <SecondarySidebar.Section
              key={section.heading}
              heading={section.heading}
            >
              {visibleItems.map((item) => (
                <SecondarySidebar.Item
                  key={item.path}
                  path={`${path}${item.path}`}
                  exact={item.exact}
                >
                  {item.label}
                </SecondarySidebar.Item>
              ))}
            </SecondarySidebar.Section>
          ) : null;
        })}
      </SecondarySidebar.Scroll>
    </SecondarySidebar>
  );
};
