import {
  validateRequireField,
  validateAlphanumericId,
} from "../../Generic/core-validations";

export const validate = (values, requiredFields, component) => {
  const errors = {};

  validateRequireField(requiredFields, values, errors, component);

  if (values["actionId"] && values["actionId"].length > 0) {
    validateAlphanumericId("actionId", values, errors, component);
  }

  return errors;
};
