import React from "react";
import Button from "../../Button/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepType } from "./WizardContent/StepType";
import { StepNumber } from "./WizardContent/StepNumber";
import { StepSave } from "./WizardContent/StepSave";
import CampaignContext from "./CampaignContext";
import {
  createOrUpdateCampaign,
  ERROR_CAMPAIGN_CREATED,
  EDIT_CAMPAIGN,
  NEW_CAMPAIGN,
} from "../../../actions/campaign";
import { fetchGroupConfig } from "../../../actions/groups";
import { showConfirmationDialog } from "../../../actions/dialog";
import { connect } from "react-redux";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";
import { validate } from "./validation";
import "../../Wizard/Wizard.scss";
import { AVAILABLE_CAMPAIGN_TYPES } from "./WizardConstants";
import StepName from "./WizardContent/StepName";
import { ERROR_GROUP_CONFIG_RECEIVED } from "../../../actions/groups";
import { StepData } from "./WizardContent/StepData";

const STEP_CAMPAIGN_TYPE = 0;
const STEP_NAME = 1;
const STEP_FROM_AND_TO = 2;
const STEP_DETAILS = 3;
const STEP_DATA = 4;

function getSteps() {
  return ["Campaign", "Name", "From/To", "Details", "Data"];
}

export const calculateMinimumCallDuration = (totalCalls, callsPerSecond) => {
  // Total calls / calls per second = no of seconds (minimum) to reach full load.
  // Multiply by a latency factor that takes into account the delay in when a call gets queued in Twilio and when it's initiated.
  const minCallDuration = (totalCalls / callsPerSecond) * 1.5;
  if (isNaN(minCallDuration) || !isFinite(minCallDuration)) {
    return 0;
  }
  return Math.round(minCallDuration);
};

class CampaignCreateWizard extends React.Component {
  setCampaignName(name) {
    this.setState({ name });
  }

  setTags(tags) {
    this.setState({ tags });
  }

  setFromNumber(fromNumber) {
    this.setState({ fromNumber });
  }

  setToNumber(toNumber) {
    this.setState({ toNumber });
  }

  setActions(actions) {
    this.setState({ actions });
  }

  setAction(action) {
    this.setState({ action });
  }

  setType(type) {
    this.setState({ type });
  }

  handleTotalCallsChange(totalCalls) {
    const minCallDuration = calculateMinimumCallDuration(
      totalCalls,
      this.state.callsPerSecond
    );

    this.setState({
      totalCalls: totalCalls * 1,
      callDuration: minCallDuration,
    });
  }

  setNoAnswerTimeout(noAnswerTimeout) {
    this.setState({ noAnswerTimeout: noAnswerTimeout * 1 });
  }

  setCallDuration(callDuration) {
    this.setState({ callDuration: callDuration * 1 });
  }

  handleCallsPerSecondChange(callsPerSecond) {
    const minCallDuration = calculateMinimumCallDuration(
      this.state.totalCalls,
      callsPerSecond
    );

    this.setState({
      callsPerSecond: callsPerSecond * 1,
      callDuration: minCallDuration,
    });
  }

  setAnswerTreatment(answerTreatment) {
    this.setState({ answerTreatment });
  }

  setAnswerTreatmentData(answerTreatmentData) {
    this.setState({ answerTreatmentData });
  }

  setDescription(description) {
    this.setState({ description });
  }

  handleSave() {
    let { createOrUpdateCampaign } = this.props;
    let campaign = {
      campaignId: this.state.campaignId,
      type: this.state.type,
      name: this.state.name,
      description: this.state.description,
      tags: this.state.tags,

      fromNumber: this.state.fromNumber,
      toNumber: this.state.toNumber,

      totalCalls: this.state.totalCalls,
      noAnswerTimeout: this.state.noAnswerTimeout,
      callDuration: this.state.callDuration,
      callsPerSecond: this.state.callsPerSecond,
      answerTreatment: this.state.answerTreatment,
      answerTreatmentData: this.state.answerTreatmentData,
    };
    const data = new FormData();
    Object.keys(campaign).forEach((key) => {
      // dropzone file upload provides an array, we only want to have the first file
      if (key === "answerTreatmentData" && campaign[key]) {
        data.append(key, campaign[key][0]);
      } else if (Array.isArray(campaign[key]) && campaign[key].length > 0) {
        campaign[key].forEach((val, index) => {
          data.append(`${key}.${index}`, val);
        });
      } else if (campaign[key]) {
        data.append(key, campaign[key]);
      }
    });

    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      createOrUpdateCampaign(data);
    } else {
      this.showValidationError();
    }
  }

  componentDidMount() {
    this.props.fetchGroupConfig();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;

    if (
      nextProps.status === NEW_CAMPAIGN ||
      nextProps.status === EDIT_CAMPAIGN
    ) {
      this.setFormSate(nextProps);
      if (nextProps.groupConfig) {
        this.setState({
          cpsLimit: nextProps.groupConfig.account.cpsLimit,
          fromNumbers: nextProps.groupConfig.fromNumbers,
          toNumbers: nextProps.groupConfig.toNumbers,
        });
      }
    } else if (nextProps.status === ERROR_CAMPAIGN_CREATED) {
      const { errorMsg } = nextProps;
      showSnackbarMessage(SNACKBAR_ERROR, errorMsg);
      if (errorMsg.includes("Campaign name must be unique")) {
        this.setState({
          error_name: true,
          errorMessage_name: errorMsg,
          activeStep: STEP_NAME,
        });
      }
    } else if (nextProps.status === ERROR_GROUP_CONFIG_RECEIVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while receiving group configuration."
      );
    }
  }

  constructor() {
    super();

    this.getStepContent = this.getStepContent.bind(this);

    this.setType = this.setType.bind(this);
    this.setCampaignName = this.setCampaignName.bind(this);
    this.setTags = this.setTags.bind(this);
    this.setFromNumber = this.setFromNumber.bind(this);

    this.setToNumber = this.setToNumber.bind(this);

    this.setDescription = this.setDescription.bind(this);

    this.handleTotalCallsChange = this.handleTotalCallsChange.bind(this);
    this.setNoAnswerTimeout = this.setNoAnswerTimeout.bind(this);
    this.setCallDuration = this.setCallDuration.bind(this);
    this.handleCallsPerSecondChange =
      this.handleCallsPerSecondChange.bind(this);
    this.setAnswerTreatment = this.setAnswerTreatment.bind(this);
    this.setAnswerTreatmentData = this.setAnswerTreatmentData.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.setFormSate = this.setFormSate.bind(this);

    this.initFormState = {
      campaignId: "-1",
      activeStep: 0,
      type: "",
      setType: this.setType,
      name: "",
      setCampaignName: this.setCampaignName,
      description: "",
      setDescription: this.setDescription,

      tags: [],
      setTags: this.setTags,

      fromNumber: "",
      setFromNumber: this.setFromNumber,

      toNumber: "",
      setToNumber: this.setToNumber,

      totalCalls: 0,
      handleTotalCallsChange: this.handleTotalCallsChange,

      noAnswerTimeout: 0,
      setNoAnswerTimeout: this.setNoAnswerTimeout,

      callDuration: 0,
      setCallDuration: this.setCallDuration,

      callsPerSecond: 0,
      handleCallsPerSecondChange: this.handleCallsPerSecondChange,

      answerTreatment: "",
      setAnswerTreatment: this.setAnswerTreatment,

      answerTreatmentData: null,
      setAnswerTreatmentData: this.setAnswerTreatmentData,

      availableTypes: AVAILABLE_CAMPAIGN_TYPES,

      fromNumbers: [],
      toNumbers: [],
      cpsLimit: 0,

      error_name: false,
      errorMessage_name: "",
    };

    this.state = this.initFormState;
  }

  setFormSate(props) {
    this.setState(this.initFormState);

    let { data } = props;
    if (data) {
      const minCallDuration = calculateMinimumCallDuration(
        data.totalCalls,
        data.callsPerSecond
      );

      this.setState({
        campaignId: data.campaignId,
        type: data.type,
        name: data.name ? data.name + " (Copy)" : data.name,
        description: data.description,

        tags:
          !data.tags ||
          typeof data.tags === "string" ||
          data.tags instanceof String
            ? []
            : data.tags,

        fromNumber: data.fromNumber,
        toNumber: data.toNumber,
        totalCalls: data.totalCalls,
        noAnswerTimeout: data.noAnswerTimeout,
        callDuration:
          data.callDuration > minCallDuration
            ? data.callDuration
            : minCallDuration,
        callsPerSecond: data.callsPerSecond,
        answerTreatment: data.answerTreatment,
      });
    }
  }

  showValidationError() {
    let { showSnackbarMessage } = this.props;
    switch (this.state.activeStep) {
      case STEP_CAMPAIGN_TYPE:
        showSnackbarMessage(SNACKBAR_ERROR, "Please select the Campaign Type.");
        return;
      case STEP_NAME:
        showSnackbarMessage(SNACKBAR_ERROR, "Please enter a Campaign Name.");
        return;
      case STEP_FROM_AND_TO:
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please select/enter the From Number."
        );
        return;
      case STEP_DETAILS:
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please enter valid campaign details."
        );
        return;
      case STEP_DATA:
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please review your campaign data."
        );
    }
  }

  validateCurrentStep() {
    let errors = {};

    switch (this.state.activeStep) {
      case STEP_CAMPAIGN_TYPE:
        return (errors = validate(this.state, ["type"], this));
      case STEP_NAME:
        return (errors = validate(this.state, ["name"], this));
      case STEP_FROM_AND_TO:
        return (errors = validate(
          this.state,
          ["fromNumber", "toNumber"],
          this
        ));
      case STEP_DETAILS:
        return (errors = validate(
          this.state,
          ["totalCalls", "noAnswerTimeout", "callDuration", "callsPerSecond"],
          this
        ));
      case STEP_DATA:
        return (errors = validate(this.state, [], this));
    }

    return errors;
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case STEP_CAMPAIGN_TYPE:
        return <StepType />;
      case STEP_NAME:
        return <StepName />;
      case STEP_FROM_AND_TO:
        return <StepNumber />;
      case STEP_DETAILS:
        return <StepSave />;
      case STEP_DATA:
        return <StepData />;
      default:
        return "Unknown step";
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      this.showValidationError();
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const steps = getSteps();

    const { activeStep } = this.state;
    const displayPrev = this.state.activeStep > 0;
    const displayNext = this.state.activeStep < steps.length - 1;
    const displaySave = this.state.activeStep === steps.length - 1;

    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} className="wizard__progress">
          {steps.map((label, index) => {
            const labelProps = {};
            if (index === activeStep) {
              labelProps.icon = (
                <div className="step step__active">{label}</div>
              );
            }
            if (index > activeStep) {
              labelProps.icon = (
                <div className="step step__future">{label}</div>
              );
            }
            if (index < activeStep) {
              labelProps.icon = <div className="step step__past">{label}</div>;
            }
            return (
              <Step key={label}>
                <StepLabel icon={index + 1} {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        <div className="wizard__content">
          <CampaignContext.Provider value={this.state}>
            {this.getStepContent(this.state.activeStep)}
          </CampaignContext.Provider>
        </div>

        <div className="wizard__btn-container">
          {displayPrev && (
            <Button
              onClick={this.handleBack}
              buttonText={"Previous"}
              className={"btnColoured"}
            />
          )}
          {displayNext && (
            <Button
              className={"btnSolid"}
              buttonText={"Next"}
              onClick={this.handleNext}
            />
          )}
          {displaySave && (
            <Button
              asyncButton={true}
              buttonText={"Save"}
              className={"btnSolid"}
              onClick={this.handleSave}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  createOrUpdateCampaign: createOrUpdateCampaign,
  showConfirmationDialog: showConfirmationDialog,
  showSnackbarMessage: showSnackbarMessage,
  fetchGroupConfig: fetchGroupConfig,
};

const mapStateToProps = (state) => ({
  status: state.campaign.status || state.group.status,
  errorMsg: state.campaign.errorMsg,
  data: state.campaign.campaignData,
  groupConfig: state.group.config,
});

const ConnectedCampaignCreateWizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCreateWizard);

export default ConnectedCampaignCreateWizard;
