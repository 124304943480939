import React, { useState, useEffect } from "react";
import {
  ButtonRefactored as Button,
  Stack,
  Modal,
  InputText,
  InputSelect,
} from "@operata/adagio";
import { auth } from "../../../auth/Auth";
import { EventSourceFormDataType } from "./EventBridge";
import { useSelector } from "react-redux";
import {
  isValidAwsAccount,
  isValidEventSourceName,
} from "./EventBridgeSettingsHelpers";

const REGION_LIST = [
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-east-1",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-northeast-3",
  "ca-central-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-south-1",
  "eu-north-1",
  "me-south-1",
  "sa-east-1",
];

type ModalAddEventBridgeProps = {
  onClose: () => void;
  handleSubmit: (eventSourceFormData: EventSourceFormDataType) => void;
  isCreating: boolean;
};

const getEventSourcePrefix = () => {
  return `aws.partner/operata.com/${auth.getCurrentGroupId()}/`;
};

function ModalAddEventBridge({
  onClose,
  handleSubmit,
  isCreating,
}: ModalAddEventBridgeProps) {
  const [awsRegion, setAwsRegion] = useState(REGION_LIST[0]);
  const [awsAccount, setAwsAccount] = useState("");
  const [eventSourceName, setEventSourceName] = useState("");
  const [isAwsAccountValid, setIsAwsAccountValid] = useState(true);
  const [isEventSourceNameValid, setIsEventSourceNameValid] = useState(true);

  const apiStatus = useSelector(
    (state: { eventBridge: { status: string } }) => state.eventBridge.status
  );

  const submitForm = () => {
    const awsValid = isValidAwsAccount(awsAccount);
    const eventSourceValid = isValidEventSourceName(eventSourceName);
    setIsAwsAccountValid(awsValid);
    setIsEventSourceNameValid(eventSourceValid);

    if (awsValid && eventSourceValid) {
      const eventSourceSubmission = {
        awsRegion: awsRegion,
        eventSourceName: getEventSourcePrefix() + eventSourceName,
        accountId: awsAccount,
      };
      handleSubmit(eventSourceSubmission);
    }
  };

  const handleAwsAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAwsAccount(e.target.value);
  };

  const handleEventSourceNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventSourceName(e.target.value);
  };

  return (
    <Modal isOpen onClose={onClose} width="small">
      <Modal.Header border>Add Event Source</Modal.Header>
      <Modal.Content>
        <Stack direction="column" gap="medium" padding="medium" block>
          <InputSelect
            label="AWS Region"
            selectedValue={awsRegion}
            setSelectedValue={setAwsRegion}
          >
            {REGION_LIST.map((region) => (
              <InputSelect.Option key={region} value={region} />
            ))}
          </InputSelect>
          <InputText
            label="Event Source Name Prefix"
            value={getEventSourcePrefix()}
            size="medium"
            disabled
          />
          <Stack.Divider />
          <InputText
            label="Event Source Name"
            value={eventSourceName}
            onChange={handleEventSourceNameChange}
            size="medium"
            tone={isEventSourceNameValid ? undefined : "danger"}
            message={
              isEventSourceNameValid
                ? undefined
                : "Event Source Name is required and can only contain alphanumeric characters, hyphens (-), underscores (_), and periods (.)"
            }
          />
          <InputText
            label="AWS Account"
            value={awsAccount}
            onChange={handleAwsAccountChange}
            description="12 Digit AWS Account Number"
            size="medium"
            tone={isAwsAccountValid ? undefined : "danger"}
            message={isAwsAccountValid ? undefined : "Invalid Account Number"}
          />
        </Stack>
      </Modal.Content>
      <Modal.Footer background>
        <Stack direction="row" gap="tiny" justify="end">
          <Button onClick={onClose} type="tertiary" size="medium">
            Cancel
          </Button>
          {isCreating ? (
            <Button type="primary" size="medium" loading disabled>
              Creating...
            </Button>
          ) : (
            <Button
              onClick={submitForm}
              type="primary"
              size="medium"
              disabled={!(awsAccount && eventSourceName)}
            >
              Create
            </Button>
          )}
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddEventBridge;
