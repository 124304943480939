import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { PropsRoute } from "../UNSAFE_route";
import { Configs } from "../Configs/Configs";
import store from "../../store/store";
import { changePath } from "../../actions/breadcrumb";
import Campaigns from "../Campaign/Campaigns";
import CampaignHistory from "../Campaign/CampaignHistory";
import CampaignView from "../Campaign/CampaignView";
import HistoryLists from "../History/HistoryLists";
import Actions from "../Actions/Actions";
import UserProfile from "../User/UserProfile";
import User from "../User/Users";
import UserForm from "../User/UserForm";
import GroupListTabs from "../Group/GroupListTabs";
import MemberForm from "../User/MemberForm";
import GroupForm from "../Group/GroupForm";
import Logs from "../GSM/Logs/Logs";
import CallReport from "../GSM/CallReport/CallReport";
import Monitor from "../GSM/Monitor/MonitorsList";
import Insights from "../GSM/Insights/Insights";
import Status from "../GSM/Status/Status";
import TestsList from "../Heartbeat/Tests/TestsList";
import LogsList from "../Heartbeat/Logs/LogsList";
import HeartbeatInsights from "../Heartbeat/Insights/Insights";
import Report from "../Heartbeat/Report/Report";
import { prefixedPath } from "../../utils/deeplinks";
import NotFound from "../NotFound/NotFound";
import history from "../../auth/history";
import Canvas from "../Canvas/Canvas";
import { useDispatch } from "react-redux";
import { pathChanged } from "../../actions/location";
import { auth } from "../../auth/Auth";
import AppUsage from "../AppUsage/AppUsage";
import ExtensionCoverage from "../ExtensionCoverage/ExtensionCoverage";

import TopLinearProgressBar from "../Progress/TopLinearProgressBar";
import Header from "../Header/Header";
import MainContent from "../Main/Content";
import Home from "../Home/Home";
import { useFeatureFlags } from "../../FeatureFlags";
import CallOnAPage from "../CallOnAPage/CallOnAPage";
import ExplorerCalls from "../Explorer/ExplorerCalls";
import Playbooks from "../Playbooks/Playbooks";

const fetchGroups = () => {
  auth.getRenewedProfile();
};

// eslint-disable-next-line react/display-name
const WithHeader = (Component) => (props) => {
  return (
    <main>
      <Header />
      <TopLinearProgressBar />
      <MainContent>
        <Component {...props} />
      </MainContent>
    </main>
  );
};

// eslint-disable-next-line react/display-name
const WithoutHeader = (Component) => (props) => {
  return (
    <main>
      <TopLinearProgressBar />
      <MainContent>
        <Component {...props} />
      </MainContent>
    </main>
  );
};

// eslint-disable-next-line react/display-name
const WithoutMainContent = (Component) => (props) => {
  return (
    <main>
      <Header />
      <TopLinearProgressBar />
      <Component {...props} />
    </main>
  );
};

/*Done
 */
const extensionCoverage = WithoutMainContent(ExtensionCoverage);
const heartbeatInsights = WithoutMainContent(HeartbeatInsights);
const explorerCalls = WithoutMainContent(ExplorerCalls);
const logs = WithoutMainContent(Logs);
const configs = WithoutMainContent(Configs);
const campaigns = WithoutMainContent(Campaigns);
const historyLists = WithoutMainContent(HistoryLists);
const actions = WithoutMainContent(Actions);
const groupListTabs = WithoutMainContent(GroupListTabs);
const user = WithoutMainContent(User);
const appUsage = WithoutMainContent(AppUsage);
/**
 */

/**
 * Cant see?
 *
 */

const home = WithoutHeader(Home);
const campaignHistory = WithHeader(CampaignHistory);
const campaignView = WithHeader(CampaignView);
const userProfile = WithHeader(UserProfile);
const userForm = WithHeader(UserForm);
const memberForm = WithHeader(MemberForm);
const groupForm = WithHeader(GroupForm);
const callReport = WithHeader(CallReport);
const monitor = WithHeader(Monitor);
const insights = WithHeader(Insights);
const status = WithHeader(Status);
const report = WithHeader(Report);
const logsList = WithHeader(LogsList);
const testsList = WithHeader(TestsList);
const canvas = WithoutHeader(Canvas);
const callOnAPage = WithoutHeader(CallOnAPage);
const playbooks = WithoutHeader(Playbooks);
/** */

export default function Routes() {
  let { pathname } = useLocation();
  const dispatch = useDispatch();

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    let currentGroupID = auth.getCurrentGroupId() + "/";
    let analyticsPath = pathname.replace(currentGroupID, "");
    window.analytics.page(analyticsPath);

    dispatch(pathChanged(pathname));
  }, [dispatch, pathname]);

  return (
    <Switch>
      <PropsRoute
        path={prefixedPath("/home")}
        component={home}
        reduxPath="home"
        breadcrumb={[{ name: "Home", path: "/home" }, "Home"]}
        store={store}
        action={changePath}
        history={history}
        featureFlags={featureFlags}
      />
      <PropsRoute
        path={prefixedPath("/configs")}
        component={configs}
        reduxPath="configs"
        breadcrumb={[{ name: "Configs", path: "/settings" }, "Home"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/")}
        exact
        component={home}
        reduxPath="home"
        breadcrumb={[{ name: "Dashboard", path: "/" }, "Home"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/dashboard")}
        component={home}
        reduxPath="home"
        breadcrumb={[{ name: "Dashboard", path: "/dashboard" }, "Home"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/campaigns")}
        component={campaigns}
        reduxPath="campaigns"
        breadcrumb={[{ name: "Simulate", path: "/campaigns" }, "Campaigns"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/campaign/history/:campaignId")}
        component={campaignHistory}
        reduxPath="campaignhistory"
        breadcrumb={[
          { name: "Simulate", path: "/campaigns" },
          { name: "${campaignName}", dynamic: true },
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/campaign/view/:campaignId/:runId")}
        component={campaignView}
        reduxPath="campaignview"
        breadcrumb={[
          { name: "Simulate", path: "/campaigns" },
          {
            name: "${campaignName}",
            path: "/campaign/history/${campaignId}",
            dynamic: true,
          },
          { name: "${runDescription}", dynamic: true },
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/history/lists")}
        component={historyLists}
        reduxPath="historylists"
        breadcrumb={[{ name: "History", path: "/history/lists" }, "Lists"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/workflows")}
        component={actions}
        reduxPath="workflows"
        breadcrumb={[{ name: "Workflows", path: "/workflows" }, "List"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/profile")}
        component={userProfile}
        reduxPath="userprofile"
        breadcrumb={["Profile"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/users")}
        component={user}
        reduxPath="users"
        breadcrumb={[{ name: "Admin", path: "/admin/users" }, "Teammates"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/billing")}
        component={appUsage}
        reduxPath="billing"
        breadcrumb={[{ name: "Admin", path: "/admin/billing" }, "Group usage"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/user/:id")}
        component={userForm}
        reduxPath="userform"
        breadcrumb={[
          { name: "Admin", path: "/admin/users" },
          "New/Edit Teammate",
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/groups")}
        component={groupListTabs}
        reduxPath="grouplisttabs"
        breadcrumb={[{ name: "Admin", path: "/admin/users" }, "Groups"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/extutil")}
        component={extensionCoverage}
        reduxPath="extutil"
        breadcrumb={[{ name: "Admin", path: "/admin/extutil" }, "Coverage"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/admin/group/:groupId/member/0")}
        component={memberForm}
        reduxPath="memberform"
        breadcrumb={[{ name: "Admin", path: "/admin/users" }, "Add Teammate"]}
        store={store}
        action={changePath}
        history={history}
        fetchGroups={fetchGroups}
      />
      <PropsRoute
        path={prefixedPath("/admin/group/:id")}
        component={groupForm}
        reduxPath="groupform"
        breadcrumb={[{ name: "Admin", path: "/admin/users" }, "New/Edit Group"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/jobs/:jobId/report")}
        component={callReport}
        reduxPath="callreport"
        breadcrumb={[
          { name: "GSM", path: "/gsm" },
          { name: "Jobs", path: "/gsm/logs" },
          "Report Card",
        ]}
        store={store}
        action={changePath}
        actionPath={prefixedPath("gsm/monitors/")}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/logs")}
        component={logs}
        reduxPath="gsmlogs"
        breadcrumb={[{ name: "GSM", path: "/gsm" }, "Logs"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/monitors")}
        component={monitor}
        reduxPath="gsmmonitors"
        breadcrumb={[
          { name: "GSM", path: "/gsm" },
          { name: "Monitors", path: "/gsm/monitors" },
          "Tests",
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/insights")}
        component={insights}
        reduxPath="gsminsights"
        breadcrumb={[{ name: "GSM", path: "/gsm" }, "Insights"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/status")}
        component={status}
        reduxPath="gsmstatus"
        breadcrumb={[{ name: "GSM", path: "/gsm" }, "Status"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/heartbeat/insights")}
        component={heartbeatInsights}
        reduxPath="hbinsights"
        breadcrumb={[
          { name: "Heartbeat", path: "/gsm/heartbeat/insights" },
          "Insights",
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/heartbeat/jobs/:jobId/report")}
        component={report}
        reduxPath="hbreport"
        breadcrumb={[
          {
            name: "Heartbeat",
            path: "/gsm/heartbeat/logs",
          },
          { name: "Logs", path: "/gsm/heartbeat/logs" },
          "Report",
        ]}
        store={store}
        action={changePath}
        actionPath={prefixedPath("gsm/heartbeat/")}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/heartbeat/logs")}
        component={logsList}
        reduxPath="hblogslist"
        breadcrumb={[
          { name: "Heartbeat", path: "/gsm/heartbeat/logs" },
          "Logs",
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/gsm/heartbeat")}
        component={testsList}
        reduxPath="hbtestslist"
        breadcrumb={[{ name: "Heartbeat", path: "/gsm/heartbeat" }, "Tests"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/call/:contactId")}
        component={callOnAPage}
        reduxPath="callonapage"
        breadcrumb={[{ name: "Call Details", path: "/history/lists/call" }]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/explorer")}
        component={explorerCalls}
        reduxPath="explorercalls"
        breadcrumb={[{ name: "Explorer", path: "/explorer" }, "Calls and Logs"]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/explorer")}
        component={explorerCalls}
        reduxPath="explorercalls"
        breadcrumb={[
          { name: "Explorer", path: "/explorer/calls" },
          "Calls and Logs",
        ]}
        store={store}
        action={changePath}
        history={history}
      />
      <PropsRoute
        path={prefixedPath("/playbooks")}
        component={playbooks}
        reduxPath="playbooks"
        store={store}
        action={changePath}
        history={history}
      />
      <Route path={prefixedPath("/canvas")} component={canvas} />
      <Route component={NotFound} />
    </Switch>
  );
}
