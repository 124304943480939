import React from "react";
import { Skeleton, Table } from "@operata/adagio";
import { EventBridgeSource } from "./EventBridge";
import EventBridgeSourceRow from "./EventBridgeSourceRow";

type EventBridgeTableProps = {
  eventSourceData: EventBridgeSource[];
  onDeleteSelect: (eventSource: EventBridgeSource) => void;
  loading: boolean;
};

const LOADING_STATUS_NUM_ROWS = 5;

const EventBridgeTableRowLoading = () => {
  return (
    <Table.Row>
      <Table.Cell>
        <Skeleton loading width={80} height={16} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton loading width={480} height={16} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton loading width={80} height={16} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton loading width={80} height={16} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton width={32} height={32} />
      </Table.Cell>
    </Table.Row>
  );
};

const EventBridgeTable = ({
  eventSourceData,
  onDeleteSelect,
  loading,
}: EventBridgeTableProps) => {
  return (
    <Table>
      <thead>
        <Table.Row>
          <Table.Header>AWS Region</Table.Header>
          <Table.Header>Event Source Name</Table.Header>
          <Table.Header>AWS Account</Table.Header>
          <Table.Header width={0}>Status</Table.Header>
          <Table.Header width={0} />
        </Table.Row>
      </thead>
      <tbody>
        {loading
          ? Array.from(Array(LOADING_STATUS_NUM_ROWS)).map((_, index) => (
              <EventBridgeTableRowLoading key={`loading-${index}`} />
            ))
          : eventSourceData.map((eventSource) => (
              <EventBridgeSourceRow
                key={eventSource.eventSourceId}
                eventSource={eventSource}
                onDeleteSelect={onDeleteSelect}
              />
            ))}
      </tbody>
    </Table>
  );
};

export default EventBridgeTable;
