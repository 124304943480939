import {
  GROUPS_RECEIVED,
  IMPORT_DASHBOARD_COMPLETE,
  ERROR_DASHBOARD_IMPORT,
  RESET_DASHBOARD_IMPORT_STATUS,
  REINDEX_GROUP_DATA_COMPLETE,
  ERROR_REINDEX_GROUP_DATA,
  CLOSE_GROUP_SWITCH,
  OPEN_GROUP_SWITCH,
  RESET_REINDEX_GROUP_DATA_STATUS,
  CANVAS_ENABLED,
  ERROR_CANVAS_ENABLE,
} from "../actions/groups";

export interface Group {
  id: string;
  customerName: string;
  customerID: string;
  name: string;
  region: string;
  status: string;
}

export interface GroupState {
  loading: boolean;
  data: Group[];
  message?: string;
  groupSwitchState: string;
  status?: string;
  random?: number;
}

export interface Action {
  type: string;
  groupList?: Group[];
  message?: string;
}

const groups = (
  state: GroupState = {
    loading: false,
    data: [],
    message: "",
    groupSwitchState: CLOSE_GROUP_SWITCH,
  },
  action: Action
): GroupState => {
  switch (action.type) {
    case GROUPS_RECEIVED:
      return {
        ...state,
        data: action.groupList || [],
        loading: false,
        status: GROUPS_RECEIVED,
      };
    case IMPORT_DASHBOARD_COMPLETE:
      return {
        ...state,
        loading: false,
        status: IMPORT_DASHBOARD_COMPLETE,
        random: Math.random() * 10000,
      };
    case REINDEX_GROUP_DATA_COMPLETE:
      return {
        ...state,
        loading: false,
        status: REINDEX_GROUP_DATA_COMPLETE,
        random: Math.random() * 10000,
      };
    case RESET_DASHBOARD_IMPORT_STATUS:
      return {
        ...state,
        loading: false,
        status: RESET_DASHBOARD_IMPORT_STATUS,
        random: Math.random() * 10000,
      };
    case RESET_REINDEX_GROUP_DATA_STATUS:
      return {
        ...state,
        loading: false,
        status: RESET_REINDEX_GROUP_DATA_STATUS,
        random: Math.random() * 10000,
      };
    case ERROR_DASHBOARD_IMPORT:
      return {
        ...state,
        loading: false,
        status: ERROR_DASHBOARD_IMPORT,
        message: action.message,
        random: Math.random() * 10000,
      };
    case ERROR_REINDEX_GROUP_DATA:
      return {
        ...state,
        loading: false,
        status: ERROR_REINDEX_GROUP_DATA,
        message: action.message,
        random: Math.random() * 10000,
      };
    case CLOSE_GROUP_SWITCH:
      return { ...state, groupSwitchState: CLOSE_GROUP_SWITCH };
    case OPEN_GROUP_SWITCH:
      return { ...state, groupSwitchState: OPEN_GROUP_SWITCH };
    case CANVAS_ENABLED:
      return {
        ...state,
        loading: false,
        status: CANVAS_ENABLED,
        message: action.message,
      };
    case ERROR_CANVAS_ENABLE:
      return {
        ...state,
        loading: false,
        status: ERROR_CANVAS_ENABLE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default groups;
