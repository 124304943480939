import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";

const TableResizeRoot = styled("div")({
  position: "absolute",
});

const Resizer = styled("div")({
  position: "absolute",
  width: "1px",
  height: "100%",
  left: "100px",
  cursor: "ew-resize",
  border: "0.1px solid rgba(224, 224, 224, 1)",
});

const TableResize = ({ setResizeable, updateDividers }) => {
  const [state, setState] = useState({
    resizeCoords: {},
    priorPosition: {},
    startPosition: 0,
    tableWidth: "100%",
    tableHeight: "100%",
    isResize: false,
    id: null,
  });

  const windowWidth = useRef(null);
  const cellsRef = useRef({});
  const tableRef = useRef(null);

  const handleResize = () => {
    if (window.innerWidth !== windowWidth.current) {
      windowWidth.current = window.innerWidth;
      setDividers();
    }
  };

  const setDividers = () => {
    if (!tableRef.current) return;

    const tableEl = tableRef.current;
    const { width: tableWidth, height: tableHeight } =
      tableEl.getBoundingClientRect();
    const { priorPosition, resizeCoords } = state;

    let finalCells = Object.entries(cellsRef.current);

    finalCells.forEach(([key, item]) => {
      if (!item) return;

      const elRect = item.getBoundingClientRect();
      const elStyle = window.getComputedStyle(item, null);
      const left = resizeCoords[key]?.left;
      const oldLeft = priorPosition[key] || 0;
      let newLeft =
        elRect.left + item.offsetWidth - parseInt(elStyle.paddingLeft) / 2;

      if (left === oldLeft) return;

      resizeCoords[key] = { left: newLeft };
      priorPosition[key] = newLeft;
    });

    setState(
      (prevState) => ({
        ...prevState,
        tableWidth,
        tableHeight,
        resizeCoords,
        priorPosition,
      }),
      updateWidths
    );
  };

  const updateWidths = useCallback(() => {
    let lastPosition = 0;
    const { resizeCoords, tableWidth } = state;

    Object.entries(resizeCoords).forEach(([key, item]) => {
      let newWidth = Number(
        ((item.left - lastPosition) / tableWidth) * 100
      ).toFixed(2);
      lastPosition = item.left;

      const thCell = cellsRef.current[key];
      if (thCell) thCell.style.width = `${newWidth}%`;
    });
  });

  const setCellRefs = (cells, table) => {
    cellsRef.current = cells;
    tableRef.current = table;
    setDividers();
  };

  const onResizeStart = (id, e) => {
    setState((prevState) => ({
      ...prevState,
      isResize: true,
      id,
      startPosition: e.clientX,
    }));
  };

  const onResizeMove = (id, e) => {
    const { startPosition, isResize, resizeCoords } = state;

    if (isResize) {
      const leftPos = startPosition - (startPosition - e.clientX);

      const curCoord = { ...resizeCoords[id], left: leftPos };
      const newResizeCoords = { ...resizeCoords, [id]: curCoord };

      setState(
        (prevState) => ({
          ...prevState,
          resizeCoords: newResizeCoords,
        }),
        updateWidths
      );
    }
  };

  const onResizeEnd = () => {
    setState((prevState) => ({
      ...prevState,
      isResize: false,
      id: null,
    }));
  };

  useEffect(() => {
    setResizeable(setCellRefs);
    updateDividers(() =>
      setState(
        (prevState) => ({
          ...prevState,
          updateCoords: true,
        }),
        updateWidths
      )
    );

    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize, false);
  }, [handleResize, setCellRefs, setResizeable, updateDividers, updateWidths]);

  const { id, isResize, resizeCoords, tableWidth, tableHeight } = state;

  return (
    <TableResizeRoot style={{ width: tableWidth }}>
      {Object.entries(resizeCoords).map(([key, val]) => (
        <div
          key={key}
          aria-hidden="true"
          onMouseMove={(e) => onResizeMove(key, e)}
          onMouseUp={() => onResizeEnd(key)}
          style={{
            width: isResize && id === key ? tableWidth : "auto",
            position: "absolute",
            height: tableHeight,
            zIndex: 1000,
          }}
        >
          <Resizer
            aria-hidden="true"
            onMouseDown={(e) => onResizeStart(key, e)}
            style={{ left: val.left }}
          />
        </div>
      ))}
    </TableResizeRoot>
  );
};

TableResize.propTypes = {
  setResizeable: PropTypes.func.isRequired,
  updateDividers: PropTypes.func.isRequired,
};

export default TableResize;
