import React from "react";
import NavTabs from "../Tabs/Tabs";
import { NavLink } from "react-router-dom";
import "../Button/Button.scss";
import { auth } from "../../auth/Auth";
import { Stack } from "@operata/adagio";
import { StyledWrapper } from "../StyledWrapper";
import styled from "@emotion/styled";

const AllowOverflow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 15vh;
`;

function Users() {
  return (
    <React.Fragment>
      <StyledWrapper>
        <AllowOverflow>
          <Stack padding="medium">
            <NavLink
              className="button button--right"
              to={"/admin/group/" + auth.getCurrentGroupId() + "/member/0"}
              href={"#"}
            >
              <span className="button__circle">+</span>
              TeamMate
            </NavLink>

            <NavTabs />
          </Stack>
        </AllowOverflow>
      </StyledWrapper>
    </React.Fragment>
  );
}

export default Users;
