import {
  softphoneConfig,
  genesysConfig,
  apiConfig,
  simulateConfig,
  agentAlertsConfig,
  heartbeatConfig,
  niceConfig,
  datasourceConfig,
} from "../Modules";

export interface ConfigField {
  type: "string" | "boolean" | "list" | "json" | "array";
  key: string;
  title: string;
  fieldText?: string;
  description?: string;
  default?: any;
  options?: Array<string | { value: string; label: string }>;
  write?: string[];
  read?: string[];
  hide?: boolean;
  fields?: ConfigField[];
  helperTexts?: Record<string, string>;
}

export interface ConfigSection {
  fields: ConfigField[];
}

export interface ConfigModule {
  key: string;
  isCustomModule?: boolean;
  path: string;
  configFields?: ConfigField[];
}

export interface ConfigModuleDefinition {
  key: string;
  component: React.ComponentType<any>;
  configFields?: ConfigField[];
}

export const CONFIG_MODULES: Record<string, ConfigModule> = {
  SOFTPHONE_MODULE: {
    key: "SOFTPHONE_MODULE",
    path: "/",
    configFields: softphoneConfig,
  },
  GENESYS_MODULE: {
    key: "GENESYS_MODULE",
    configFields: genesysConfig,
    path: "/genesys",
  },
  NICE_MODULE: { key: "NICE_MODULE", configFields: niceConfig, path: "/nice" },
  SIMULATE_MODULE: {
    key: "SIMULATE_MODULE",
    configFields: simulateConfig,
    path: "/simulate",
  },
  API_MODULE: {
    key: "API_MODULE",
    configFields: apiConfig,
    path: "/api",
  },
  GSM_MODULE: {
    key: "GSM_MODULE",
    isCustomModule: true,
    path: "/heartbeat-tagging",
  },
  HEARTBEAT_MODULE: {
    key: "HEARTBEAT_MODULE",
    configFields: heartbeatConfig,
    path: "/heartbeat",
  },
  EVENTBRIDGE_MODULE: {
    key: "EVENTBRIDGE_MODULE",
    isCustomModule: true,
    path: "/eventbridge",
  },
  DATA_SOURCES_MODULE: {
    key: "DATA_SOURCES_MODULE",
    configFields: datasourceConfig,
    path: "/sources",
  },
  AGENT_ALERTS_MODULE: {
    key: "AGENT_ALERTS_MODULE",
    configFields: agentAlertsConfig,
    path: "/extension-alerts",
  },
};
