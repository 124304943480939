import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import store from "../../store/store";
import { windowResized } from "../../actions/window";
import { useLocation } from "react-router-dom";

const ConfigDiv = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(function ConfigDiv({ children }, ref) {
  return <div ref={ref}>{children}</div>;
});

const SuperSetDiv = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(function SuperSetDiv({ children }, ref) {
  return (
    <div ref={ref} className="main__content superset__open">
      {children}
    </div>
  );
});

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  const mainContent = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const storeMainContentWidth = () => {
    if (mainContent.current) {
      const mainContentWidth = mainContent.current.offsetWidth;
      const computedStyle = window.getComputedStyle(mainContent.current, null);
      const paddingLeft = computedStyle.getPropertyValue("padding-left");
      const paddingRight = computedStyle.getPropertyValue("padding-right");

      store.dispatch(
        windowResized(
          mainContentWidth -
            parseInt(paddingLeft, 10) -
            parseInt(paddingRight, 10)
        )
      );
    }
  };

  useEffect(() => {
    storeMainContentWidth();
    window.addEventListener("resize", storeMainContentWidth);

    return () => {
      window.removeEventListener("resize", storeMainContentWidth);
    };
  }, []);

  const isSupersetOpen =
    location.pathname.includes("/canvas") ||
    location.pathname.includes("/playbooks");

  const isConfig = location.pathname.includes("/configs");

  return (
    <>
      {isSupersetOpen ? (
        <SuperSetDiv ref={mainContent}>{children}</SuperSetDiv>
      ) : isConfig ? (
        <ConfigDiv ref={mainContent}>{children}</ConfigDiv>
      ) : (
        <div ref={mainContent} className="main__content">
          {children}
        </div>
      )}
    </>
  );
};

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContent;
