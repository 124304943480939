import {
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const agentAlertsConfig: ConfigField[] = [
  {
    type: `boolean`,
    key: `AGENT_ALERT_DISPLAY_TOASTS`,
    title: `Toast Notification`,
    fieldText: `Enable toast banner notifications on alerts`,
    description: `Enabling this causes a toast to pop when the agent receives an alert`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_MICROPHONE_NOT_SHARED`,
    title: `Microphone Not Shared`,
    fieldText: `Enable microphone not shared alert`,
    description: `Alert the Agent if the microphone is not shared`,
    default: "true",
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_THRESHOLD_MOS`,
    title: `Network Performance (MOS)`,
    fieldText: `Agent Alert Threshold - Network Performance (MOS)`,
    description: `Agent Alert Threshold - Network Performance (MOS). Defaults: Less than 3.5 for more than 10 seconds`,
    fields: [
      {
        type: `string`,
        key: `low_mos`,
        title: "Low Threshold",
        default: "3.5",
      },
      {
        type: `string`,
        key: `very_low_mos`,
        title: "Very Low Threshold",
        default: `2`,
      },
      {
        type: `string`,
        key: `seconds`,
        title: "Duration",
        default: `10`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_COMPUTER_PERFORMANCE`,
    title: `Poor Computer Performance (%)`,
    fieldText: `Agent Alert Threshold - Poor Computer Performance (%)`,
    description: `Agent Alert Threshold - Poor Computer Performance (%). Defaults: More than 80% used for more than 30 seconds`,
    fields: [
      {
        type: `string`,
        key: `low_performance`,
        title: "Low Threshold",
        default: "80",
      },
      {
        type: `string`,
        key: `very_low_performance`,
        title: "Very Low Threshold",
        default: "90",
      },
      {
        type: `string`,
        key: `seconds`,
        title: "Duration",
        default: `30`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `string`,
    key: `AGENT_ALERT_OPTIONAL_COMMENT_PROMPT`,
    title: `Optional Comment Prompt`,
    fieldText: `Customize Optional Comment Prompt`,
    description: `Additional prompt agents will see when reporting an issue`,
    default: "",
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_JABRA_DEVICE_PROMPT`,
    title: `Jabra Headset Prompt`,
    fieldText: `Enable Jabra headset prompt`,
    description: `Enabling the Jabra device detection alert will allow agents to be prompted if they are not using their Jabra device`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_TALKING_ON_MUTE`,
    title: `Talking On Mute`,
    fieldText: `Enable talking on mute alert`,
    description: `Enabling the talking on mute alert will allow agents to be prompted if they are talking while muted`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_FREQUENT_MUTING`,
    title: `Frequent Muting`,
    fieldText: `Enable frequent muting alert`,
    description: `Enabling the frequent muting alert will prompt agents if they frequently muting their microphone`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_BACKGROUND_NOISE`,
    title: `Background Noise`,
    description: `Enabling the background noise very high alert will prompt agents if they are in a very high background noise environment`,
    fields: [
      {
        type: `boolean`,
        key: `enabled`,
        title: "Enabled",
        fieldText: `Enable alert`,
        default: false,
      },
      {
        type: `string`,
        key: `high_threshold`,
        title: "High Threshold (dB)",
        default: `70`,
      },
      {
        type: `string`,
        key: `base_threshold`,
        title: "Base Threshold (dB)",
        default: `55`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_BACKGROUND_NOISE_VOLUME_UP`,
    title: `Background Noise (Volume Tap)`,
    fieldText: `Enable background noise (when volume increasing) alert`,
    description: `Enabling the background noise + volume up tap alert will prompt agents if they are in a high background noise environment and they are increasing the volume on their headset to compensate`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_BACKGROUND_NOISE_FREQUENT_MUTING`,
    title: `Background Noise (Frequent Muting)`,
    fieldText: `Enable background noise (when frequent muting) alert`,
    description: `Enabling the background noise + frequent muting alert will prompt agents if they are in a high background noise environment and they are frequently muting their microphone`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_CROSSTALK`,
    title: `Crosstalk`,
    fieldText: `Enable crosstalk (when it is excessive) alert`,
    description: `Enabling the excessive crosstalk alert will prompt agents if they and the customer are frequently talking over each other`,
    fields: [
      {
        type: `boolean`,
        key: `enabled`,
        title: "Enabled",
        fieldText: `Enable alert`,
        default: false,
      },
      {
        type: `string`,
        key: `high_threshold`,
        title: "High Threshold (%)",
        default: `90`,
      },
      {
        type: `string`,
        key: `base_threshold`,
        title: "Base Threshold (%)",
        default: `50`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_CROSSTALK_LATENCY`,
    title: `Crosstalk (Due to High Latency)`,
    fieldText: `Enable crosstalk (due to high latency) alert`,
    description: `Enabling the crosstalk + high latency alert will prompt agents if they are talking over their customer, likely because of high latency`,
    fields: [
      {
        type: `boolean`,
        key: `enabled`,
        title: "Enabled",
        fieldText: `Enable alert`,
        default: false,
      },
      {
        type: `string`,
        key: `threshold`,
        title: "Threshold (ms)",
        default: `200`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_BOOM_ARM_POSITION`,
    title: `Boom Arm Position Incorrect`,
    fieldText: `Enable boom arm position when incorrect or mis-aligned alert`,
    description: `Enabling the boom arm position alert will prompt agents if the boom arm on their Jabra device is incorrectly positioned for the best customer experience`,
    default: false,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `json`,
    key: `AGENT_ALERT_AUDIO_EXPOSURE`,
    title: `Audio Exposure Warning`,
    fieldText: `Enable warning for audio exposure alert`,
    description: `Enabling the audio exposure alert will prompt agents if they are suffering from consistently high output volume`,
    fields: [
      {
        type: `boolean`,
        key: `enabled`,
        title: "Enabled",
        fieldText: `Enable alert`,
        default: false,
      },
      {
        type: `string`,
        key: `high_threshold`,
        title: "High Threshold (dB)",
        default: `85`,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_BATTERY_LOW`,
    title: `Battery Level (Low)`,
    fieldText: `Enable battery level (low) alert`,
    description: `Enabling the battery low alert will prompt agents if the battery in their wireless Jabra device is low`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `boolean`,
    key: `AGENT_ALERT_BATTERY_VERY_LOW`,
    title: `Battery Level (Very Low)`,
    fieldText: `Enable battery level (very low) alert`,
    description: `Enabling the battery very low alert will prompt agents if the battery in their wireless Jabra device is at a critical level`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER],
  },
];
