import {
  CALLSTATS_RECEIVED,
  CALLSTAT_DETAILS_RECEIVED,
  ERROR_CALLSTATS_RETRIEVAL,
  RESET_STATE_CALL_STATS,
} from "../actions/callstats";

import { CallSummary } from "../models/callstats";
import { AnyAction } from "redux";

export type CallStatsState = {
  loading: boolean;
  calls: CallSummary[];
  callsRecCount: number;
  status: string;
};

const callstats = (
  state: CallStatsState = {
    loading: false,
    calls: [],
    callsRecCount: 0,
    status: CALLSTAT_DETAILS_RECEIVED,
  },
  action: AnyAction
) => {
  switch (action.type) {
    case CALLSTATS_RECEIVED:
      return Object.assign({}, state, {
        calls: action.calls,
        callsRecCount: action.recordCount,
        loading: false,
        status: CALLSTATS_RECEIVED,
      });
    case ERROR_CALLSTATS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_CALLSTATS_RETRIEVAL,
      });
    case RESET_STATE_CALL_STATS:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_STATE_CALL_STATS,
      });
    default:
      return state;
  }
};

export default callstats;
