export const SNACKBAR_INFO = "info";
export const SNACKBAR_SUCCESS = "success";
export const SNACKBAR_ERROR = "error";
export const SNACKBAR_WARNING = "warning";

export const SNACKBAR_OPENED = "SNACKBAR_OPENED";
export const SNACKBAR_CLOSED = "SNACKBAR_CLOSED";

export const showSnackbarMessage = (mesasgeType: string, message?: string) => ({
  type: SNACKBAR_OPENED,
  messageType: mesasgeType,
  message: message,
});

export const closeSnackbar = () => ({
  type: SNACKBAR_CLOSED,
});
