import React, { useRef, useState, useEffect } from "react";
import MuiPopover from "@material-ui/core/Popover";

const Popover = ({ trigger, content, refClose, refExit, ...providedProps }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const popoverRef = useRef(null);
  const popoverActionsRef = useRef(null);

  useEffect(() => {
    if (refClose) {
      refClose(handleRequestClose);
    }
  }, [refClose]);

  useEffect(() => {
    if (open && popoverActionsRef.current) {
      popoverActionsRef.current.updatePosition();
    }
  }, [open]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleRequestClose = (cb) => {
    setOpen(false);
    if (cb && typeof cb === "function") {
      cb();
    }
  };

  const handleOnExit = () => {
    if (refExit) {
      refExit();
    }
  };

  const transformOriginSpecs = {
    vertical: "top",
    horizontal: "center",
  };

  const anchorOriginSpecs = {
    vertical: "bottom",
    horizontal: "center",
  };

  const triggerEl = React.cloneElement(<span>{trigger}</span>, {
    key: "content",
    ref: anchorRef,
    onClick: () => {
      if (trigger.props.onClick) {
        trigger.props.onClick();
      }
      handleClick();
    },
  });

  return (
    <>
      <MuiPopover
        action={(actions) => {
          popoverActionsRef.current = actions;
        }}
        elevation={2}
        open={open}
        onClose={handleRequestClose}
        onExited={handleOnExit}
        anchorEl={anchorRef.current}
        ref={popoverRef}
        anchorOrigin={anchorOriginSpecs}
        transformOrigin={transformOriginSpecs}
        {...providedProps}
      >
        {content}
      </MuiPopover>
      {triggerEl}
    </>
  );
};

export default Popover;
