import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { reduxForm } from "redux-form";
import { validate, asyncValidate } from "./memberFormValidation";
import OpTextField from "../Generic/OpTextField";
import {
  fetchUser,
  initialiseMemberForm,
  createOrUpdateUser,
  resetState
} from "../../actions/users";
import {
  USER_CREATED,
  USER_UPDATED,
  ERROR_USER_CREATED
} from "../../actions/users";
import {
  showSnackbarMessage,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR
} from "../../actions/snackbar";
import OpSelect from "../Generic/OpSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";

class MemberForm extends Component {
  constructor(props) {
    super(props);
    this.UNSAFE_componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(
      this
    );
  }

  componentDidMount() {
    let groupId = this.props.match.params.groupId;
    if (groupId && groupId.length > 0) {
      this.props.initialiseMemberForm(groupId);
    }
  }

  handleCancel = () => {
    this.props.history.push("/admin/users");
  };

  isEditUser() {
    let id = this.props.match.params.id;
    return id && id > 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === USER_CREATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Teammate created successfully."
      );
      this.props.resetState();
      this.props.history.push("/admin/users");
    } else if (nextProps.status === USER_UPDATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Teammate updated successfully."
      );
      this.props.resetState();
      this.props.history.push("/admin/users");
      this.props.fetchGroups();
    } else if (nextProps.status === ERROR_USER_CREATED) {
      this.props.showSnackbarMessage(SNACKBAR_ERROR, nextProps.message);
    }
  }

  render() {
    let { handleSubmit, pristine, submitting } = this.props;

    return (
      <React.Fragment>
        <div className="grid__container">
          <form
            className="grid__column grid__column--four"
            onSubmit={handleSubmit(this.props.createOrUpdateUser)}
          >
            <div className="form__control">
              <OpTextField name="email" label="Email" type="text" />
            </div>
            <div className="form__control">
              <OpTextField name="name" label="Name" type="text" />
            </div>
            <div className="form__control">
              <OpTextField
                name="group"
                label="Group"
                type="text"
                disabled="true"
              />
            </div>

            {this.props.initialValues &&
              this.props.initialValues.role !== "Operata Superuser" && (
                <div className="form__control">
                  <OpSelect name="role" label="Role">
                    <MenuItem value="Group Admin">Group Admin</MenuItem>
                    <MenuItem value="Group User">Group User</MenuItem>
                    <MenuItem value="Group Viewer">Group Viewer</MenuItem>
                  </OpSelect>
                </div>
              )}
            <div className="form__control form__control--buttons">
              <Button
                buttonText={"Cancel"}
                className={"btnColouredSmall"}
                disabled={pristine || submitting}
                onClick={this.handleCancel}
              />
              <Button
                type="submit"
                buttonText={"Save"}
                className="btnSolid"
                disabled={pristine || submitting}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

MemberForm.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.node,
  initialiseMemberForm: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  resetState: PropTypes.func,
  loading: PropTypes.bool,
  status: PropTypes.string,
  message: PropTypes.string,
  fetchUser: PropTypes.func,
  createOrUpdateUser: PropTypes.func,
  history: PropTypes.node,
  pristine: PropTypes.string,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  fetchGroups: PropTypes.func
};

const mapDispatchToProps = {
  fetchUser: fetchUser,
  createOrUpdateUser: createOrUpdateUser,
  showSnackbarMessage: showSnackbarMessage,
  initialiseMemberForm: initialiseMemberForm,
  resetState: resetState
};

export const mapStateToProps = state => ({
  initialValues: state.user.data,
  loading: state.user.loading,
  status: state.user.status,
  message: state.user.message
});

// eslint-disable-next-line no-class-assign
MemberForm = reduxForm({
  form: "MemberForm",
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
  enableReinitialize: true
})(MemberForm);

// eslint-disable-next-line no-class-assign
MemberForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberForm);

export default withRouter(MemberForm);
