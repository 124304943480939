import React, { Component } from "react";
import vegaEmbed from "vega-embed";

class CampaignViewStatsFailureGraph extends Component {
  componentDidMount() {
    this.showGraph();
  }

  componentDidUpdate() {
    this.showGraph();
  }

  showGraph() {
    vegaEmbed(this.refs.chartContainer, this._spec(), {
      tooltip: { theme: "dark" },
      actions: false,
    })
      .then(function () {})
      .catch(console.error);
  }

  render() {
    return <div ref="chartContainer" />;
  }

  _spec() {
    const retJson = {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      description: "A bar chart that directly encodes color names in the data.",
      data: {
        values: this.props.failedStats,
      },
      mark: "bar",
      encoding: {
        x: {
          field: "status",
          type: "nominal",
          title: "Status",
        },
        y: {
          field: "calls",
          type: "quantitative",
          title: "Calls",
        },
        color: {
          field: "color",
          type: "nominal",
          scale: null,
        },
      },
    };

    // const retJson = {
    //   $schema: 'https://vega.github.io/schema/vega/v5.json',
    //   width: 130,
    //   height: 150,

    //   data: [
    //     {
    //       name: 'table',
    //       values: this.props.failedStats,
    //       transform: [
    //         {
    //           type: 'pie',
    //           field: 'calls',
    //           startAngle: 0,
    //           endAngle: 6.29,
    //           sort: false,
    //         },
    //       ],
    //     },
    //   ],

    //   scales: [
    //     {
    //       name: 'color',
    //       type: 'ordinal',
    //       domain: ['Busy', 'Premature', 'Cancelled', 'No Answer', 'Failed', 'Successful'],
    //       range: ['red', 'orange', 'red', 'orange', 'red', 'green'],
    //     },
    //     {
    //       name: 'label',
    //       type: 'ordinal',
    //       domain: { data: 'table', field: 'status' },
    //       range: { data: 'table', field: 'status' },
    //     },
    //   ],

    //   legends: [
    //     {
    //       fill: 'color',
    //       title: 'Call Statuses',
    //       orient: 'right',
    //       encode: {
    //         symbols: {
    //           enter: {
    //             fillOpacity: { value: 0.5 },
    //           },
    //         },
    //         labels: {
    //           update: {
    //             text: { scale: 'label', field: 'value' },
    //           },
    //         },
    //       },
    //     },
    //   ],

    //   marks: [
    //     {
    //       type: 'arc',
    //       from: { data: 'table' },
    //       encode: {
    //         enter: {
    //           fill: { scale: 'color', field: 'status' },
    //           x: { signal: 'width / 2' },
    //           y: { signal: 'height / 2' },
    //           tooltip: { signal: '{Status: datum.status, Calls: datum.calls}' },
    //         },
    //         update: {
    //           startAngle: { field: 'startAngle' },
    //           endAngle: { field: 'endAngle' },
    //           innerRadius: [{ value: 40 }],
    //           outerRadius: [{ value: 60 }],
    //         },
    //       },
    //     },
    //     {
    //       type: 'text',
    //       from: { data: 'table' },
    //       encode: {
    //         enter: {
    //           x: { signal: 'width / 2' },
    //           y: { signal: 'height / 2' },
    //           radius: { value: 50 },
    //           theta: { signal: '(datum.startAngle + datum.endAngle)/2' },

    //           align: { value: 'center' },
    //           baseline: { value: 'middle' },
    //           text: [{ test: 'datum.calls > 0', field: 'calls' }],
    //           fill: [
    //             { test: "contrast('white', datum.fill) > contrast('black', datum.fill)", value: 'white' },
    //             { value: 'black' },
    //           ],
    //         },
    //       },
    //     },
    //   ],
    // };

    return retJson;
  }
}

export default CampaignViewStatsFailureGraph;
