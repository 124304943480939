import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMonthlyAppUsage } from "../../actions/appUsage";
import MUIDataTable from "../Datatables";
import { TableCell } from "@material-ui/core";
import { StyledWrapper } from "../StyledWrapper";
import { Stack } from "@operata/adagio";
const AppUsage = () => {
  const dispatch = useDispatch();
  const appUsage = useSelector((state) => state.appUsage);

  useEffect(
    () => {
      dispatch(fetchMonthlyAppUsage());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const currentMonthUsageData = (data) => {
    if (!(data instanceof Array) || !data.length) return [];
    const currentMonthData = data[0];
    return [
      [
        currentMonthData.Period,
        currentMonthData.AgentCount,
        currentMonthData.DurationRoundedMin,
        currentMonthData.AgentDurationRoundedMin,
        currentMonthData.CloudDurationRoundedMin,
        currentMonthData.HeartbeatTestCount,
      ],
    ];
  };

  const currentUsageTableTitle = (data) => {
    const desc =
      !(data instanceof Array) || !data.length
        ? ""
        : `Partial usage period for ${data[0].Period}. Each call rounded up to the nearest minute, may not include the entirety of the month.`;
    const title = "Current Usage";
    return { heading: title, description: desc };
  };

  const twelveMonthUsageTableTitle = () => {
    const title = "Previous 12 months";
    const desc =
      "Each call rounded up to the nearest minute. Note: usage reports only include data from July 2023 onwards, data before this time is not available.";
    return { heading: title, description: desc };
  };

  const twelveMonthUsageData = (data) => {
    if (!(data instanceof Array) || !data.length) return [];
    return data.slice(1).map((result) => {
      //Skip the current month from the ordered array and process
      return [
        //Backend API sends data in <MONTH>,<YEAR> format
        result.Month,
        result.AgentCount,
        result.DurationRoundedMin,
        result.AgentDurationRoundedMin,
        result.CloudDurationRoundedMin,
        result.HeartbeatTestCount,
      ];
    });
  };

  const columns = [
    {
      name: "Month",
      label: "MONTH",
    },
    {
      name: "Agent count",
      label: "Agentcount",
      options: {
        customHeadRender: (column) => {
          return (
            <TableCell
              style={{ textAlign: "right", textTransform: "uppercase" }}
            >
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "Billing minutes",
      label: "BillingMinutes",
      options: {
        customHeadRender: (column) => {
          return (
            <TableCell
              style={{ textAlign: "right", textTransform: "uppercase" }}
            >
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "Agent minutes",
      label: "AgentMinutes",
      options: {
        customHeadRender: (column) => {
          return (
            <TableCell
              style={{ textAlign: "right", textTransform: "uppercase" }}
            >
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "Cloud minutes",
      label: "CloudMinutes",
      options: {
        customHeadRender: (column) => {
          return (
            <TableCell
              style={{ textAlign: "right", textTransform: "uppercase" }}
            >
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } }),
      },
    },
    {
      name: "Assurance minutes",
      label: "AssuranceMinutes",
      options: {
        customHeadRender: (column) => {
          return (
            <TableCell
              style={{ textAlign: "right", textTransform: "uppercase" }}
            >
              {column.name}
            </TableCell>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } }),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    fixedHeader: false,
    viewColumns: false,
    pagination: false,
    search: false,
    download: false,
    sort: false,
    print: false,
  };

  return (
    <React.Fragment>
      <Stack padding="medium">
        <StyledWrapper>
          <MUIDataTable
            title={currentUsageTableTitle(appUsage.usageData)}
            data={currentMonthUsageData(appUsage.usageData)}
            columns={columns}
            options={options}
          />
          <div style={{ marginBottom: "20px" }} />
          <MUIDataTable
            title={twelveMonthUsageTableTitle()}
            data={twelveMonthUsageData(appUsage.usageData)}
            columns={columns}
            options={options}
          />
        </StyledWrapper>
      </Stack>
    </React.Fragment>
  );
};

export default AppUsage;
