import { USER_ROLE_SUPER, USER_ROLE_ADMIN } from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const apiConfig: ConfigField[] = [
  {
    type: `json`,
    key: `API_TOKENS`,
    title: `API Management`,
    fieldText: `API Management`,
    description: `The API tokens used to access the Operata API`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [],
  },
];
