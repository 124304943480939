import React from "react";
import { Button } from "@operata/ui-components";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import TestContext from "./TestContext";
import {
  createTest,
  fetchSourceNumbers,
  ERROR_TEST_CREATED,
  TEST_CREATED,
  RESET_TEST_PAGE_STATE,
  SOURCE_NUMBERS_RECEIVED,
  ERROR_SOURCE_NUMBERS_RECEIVED,
} from "../../../../actions/heartbeat";
import { connect } from "react-redux";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../../../actions/snackbar";
import { validate } from "./validation";
import "../../../Wizard/Wizard.scss";
import StepDescription from "./WizardContent/StepDescription";
import StepAgentType from "./WizardContent/StepAgentType";
import StepAgentIds from "./WizardContent/StepAgentIds";
import StepSchedule from "./WizardContent/StepSchedule";
import PropTypes from "prop-types";
import {
  DESCRIPTION_STEP,
  AGENT_TYPE_STEP,
  TARGET_AGENTS_STEP,
  SCHEDULE_STEP,
} from "../../../../constants/heartbeat";

import { fetchConfig } from "../../../../actions/config";

import {
  CONFIG_RECEIVED,
  ERROR_CONFIG_RECEIVED,
} from "../../../../reducers/config";

function getSteps() {
  return [DESCRIPTION_STEP, AGENT_TYPE_STEP, TARGET_AGENTS_STEP, SCHEDULE_STEP];
}

export const CreateTestWizardComponent = class CreateMonitorWizard extends React.Component {
  constructor(props) {
    super(props);

    this.getStepContent = this.getStepContent.bind(this);

    this.state = {
      activeStep: 0,

      description: "",
      setDescription: this.setDescription,

      agentType: "",
      setAgentType: this.setAgentType,

      agentIds: [],
      setAgentIds: this.setAgentIds,

      schedule: "",
      setSchedule: this.setSchedule,

      sourcePhoneNumbers: [],
      sourcePhoneNumber: "",
      setSourcePhoneNumber: this.setSourcePhoneNumber,

      targetPhoneNumbers: [],
      targetPhoneNumber: "",
      setTargetPhoneNumber: this.setTargetPhoneNumber,

      virtualAgentNames: [],
    };
  }

  componentDidMount() {
    this.props.fetchSourceNumbers();
    this.props.fetchConfig("HEARTBEAT_TARGET_PHONE_NUMBERS", "array");
    this.props.fetchConfig("HEARTBEAT_VIRTUAL_AGENT_NAMES", "array");
  }

  setDescription = (description) => {
    this.setState({ description });
  };

  setAgentType = (agentType) => {
    this.setState({ agentType });
  };

  setAgentIds = (agentIds) => {
    agentIds = agentIds.split(",");
    this.setState({ agentIds });
  };

  setSchedule = (schedule) => {
    this.setState({ schedule });
  };

  setSourcePhoneNumber = (sourcePhoneNumber) => {
    this.setState({ sourcePhoneNumber });
  };

  setTargetPhoneNumber = (targetPhoneNumber) => {
    this.setState({ targetPhoneNumber });
  };

  handleSave = () => {
    let { createTest } = this.props;
    let test = {
      sourcePhoneNumber: this.state.sourcePhoneNumber,
      targetPhoneNumber: this.state.targetPhoneNumber,
      description: this.state.description,
      agentType: this.state.agentType,
    };

    test = { ...test, agentIds: this.state.agentIds };
    if (this.state.schedule !== "0m") {
      test = { ...test, schedule: this.state.schedule };
    }
    let stepErrors = this.validateCurrentStep();

    if (!stepErrors["hasErrors"]) {
      createTest(test);
    } else {
      this.showValidationError();
    }
  };

  static getDerivedStateFromProps(nextProps) {
    let { showSnackbarMessage } = nextProps;
    const resetState = {
      activeStep: 0,
      sourcePhoneNumbers: nextProps.sourceNumbers || [],
      sourcePhoneNumber: "",
      targetPhoneNumbers: [],
      targetPhoneNumber: "",
      description: "",
      agentType: "",
      agentIds: [],
      schedule: "",
      virtualAgentNames: [],
    };

    if (nextProps.status === RESET_TEST_PAGE_STATE) {
      return resetState;
    }

    if (nextProps.status === ERROR_TEST_CREATED) {
      const { errorMsg } = nextProps;
      showSnackbarMessage(SNACKBAR_ERROR, errorMsg);
      return null;
    }

    if (nextProps.status === TEST_CREATED) {
      showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "A new job has been created successfully."
      );
      return resetState;
    }

    if (nextProps.status === SOURCE_NUMBERS_RECEIVED) {
      return {
        sourcePhoneNumbers: nextProps.sourceNumbers,
      };
    }
    if (nextProps.status === ERROR_SOURCE_NUMBERS_RECEIVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        `Failed to retrieve available 'From' numbers`
      );
      return null;
    }

    if (nextProps.configStatus === CONFIG_RECEIVED) {
      //Temporary reducer change and introduction of new key - configs
      //TODO: modify the config to store all config values retrieved
      if (nextProps.config && nextProps.configs) {
        var resultState = {};
        if (nextProps.configs["HEARTBEAT_TARGET_PHONE_NUMBERS"]) {
          resultState = {
            ...resultState,
            targetPhoneNumbers:
              nextProps.configs["HEARTBEAT_TARGET_PHONE_NUMBERS"],
          };
        }
        if (nextProps.configs["HEARTBEAT_VIRTUAL_AGENT_NAMES"]) {
          resultState = {
            ...resultState,
            virtualAgentNames:
              nextProps.configs["HEARTBEAT_VIRTUAL_AGENT_NAMES"],
          };
        }
        return resultState;
      } else {
        return null;
      }
    }

    if (nextProps.configStatus === ERROR_CONFIG_RECEIVED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        `An error occurred while retrieving configs.`
      );
      return null;
    }

    return null;
  }

  showValidationError() {
    let { showSnackbarMessage } = this.props;
    const steps = getSteps();
    switch (this.state.activeStep) {
      case steps.indexOf(DESCRIPTION_STEP):
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please select a 'To' and 'From' Number and enter a description."
        );
        return;
      case steps.indexOf(AGENT_TYPE_STEP):
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please select " + AGENT_TYPE_STEP + "."
        );
        return;
      case steps.indexOf(TARGET_AGENTS_STEP):
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please enter " + TARGET_AGENTS_STEP + "."
        );
        return;
      case steps.indexOf(SCHEDULE_STEP):
        showSnackbarMessage(
          SNACKBAR_ERROR,
          "Please select " + SCHEDULE_STEP + "."
        );
        return;
    }
  }

  validateCurrentStep() {
    let errors = {};
    const steps = getSteps();
    switch (this.state.activeStep) {
      case steps.indexOf(DESCRIPTION_STEP):
        return (errors = validate(
          this.state,
          ["sourcePhoneNumber", "targetPhoneNumber", "description"],
          this
        ));
      case steps.indexOf(AGENT_TYPE_STEP):
        return (errors = validate(this.state, ["agentType"], this));
      case steps.indexOf(TARGET_AGENTS_STEP):
        return (errors = validate(this.state, ["agentIds"], this));
      case steps.indexOf(SCHEDULE_STEP):
        return (errors = validate(this.state, ["schedule"], this));
    }

    return errors;
  }

  getStepContent(stepIndex) {
    const steps = getSteps();

    switch (stepIndex) {
      case steps.indexOf(DESCRIPTION_STEP):
        return <StepDescription />;
      case steps.indexOf(AGENT_TYPE_STEP):
        return <StepAgentType />;
      case steps.indexOf(TARGET_AGENTS_STEP):
        return (
          <StepAgentIds
            agentType={this.state.agentType}
            virtualAgentNames={this.state.virtualAgentNames}
          />
        );
      case steps.indexOf(SCHEDULE_STEP):
        return <StepSchedule />;
      default:
        return "Unknown step";
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      this.showValidationError();
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  render() {
    const steps = getSteps();

    const { activeStep } = this.state;
    const displayPrev = this.state.activeStep > 0;
    const displayNext = this.state.activeStep < steps.length - 1;
    const displaySave = this.state.activeStep === steps.length - 1;

    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} className="wizard__progress">
          {steps.map((label, index) => {
            const labelProps = {};
            if (index === activeStep) {
              labelProps.icon = (
                <div className="step step__active">{label}</div>
              );
            }
            if (index > activeStep) {
              labelProps.icon = (
                <div className="step step__future">{label}</div>
              );
            }
            if (index < activeStep) {
              labelProps.icon = <div className="step step__past">{label}</div>;
            }
            return (
              <Step key={label}>
                <StepLabel icon={index + 1} {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        <div className="wizard__content">
          <TestContext.Provider value={this.state}>
            {this.getStepContent(this.state.activeStep)}
          </TestContext.Provider>
        </div>

        <div className="wizard__btn-container">
          {displayPrev && (
            <Button
              onClick={this.handleBack}
              buttonText={"Previous"}
              className={"btnColoured"}
            />
          )}
          {displayNext && (
            <Button
              className={"btnSolid"}
              buttonText={"Next"}
              onClick={this.handleNext}
            />
          )}
          {displaySave && (
            <Button
              asyncButton={true}
              buttonText={"Create"}
              className={"btnSolid"}
              onClick={this.handleSave}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
};

CreateTestWizardComponent.propTypes = {
  createTest: PropTypes.func,
  fetchSourceNumbers: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  sourceNumbers: PropTypes.array,
  status: PropTypes.string,
  errorMsg: PropTypes.string,
  fetchConfig: PropTypes.func,
  configStatus: PropTypes.string,
  config: PropTypes.object,
  configs: PropTypes.object,
};

const mapDispatchToProps = {
  createTest: createTest,
  fetchSourceNumbers: fetchSourceNumbers,
  showSnackbarMessage: showSnackbarMessage,
  fetchConfig: fetchConfig,
};

const mapStateToProps = (state) => ({
  sourceNumbers: state.heartbeat.sourceNumbers,
  status: state.heartbeat.status,
  errorMsg: state.heartbeat.errorMsg,
  config: state.config.config,
  configs: state.config.configs,
  configStatus: state.config.status,
});

const CreateTestWizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTestWizardComponent);

export default CreateTestWizard;
