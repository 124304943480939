import { auth } from "../auth/Auth";
import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";

export const AGENT_CCP_LOGS_RECEIVED = "AGENT_CCP_LOGS_RECEIVED";
export const DOWNLOAD_LOGS_FETCHED = "DOWNLOAD_LOGS_FETCHED";
export const ERROR_AGENT_CCP_LOGS_RETRIEVAL = "ERROR_AGENT_CCP_LOGS_RETRIEVAL";

export const showProgress = () => ({
  type: SHOW_PROGRESS,
});

export const downloadLogsFetched = () => ({
  type: DOWNLOAD_LOGS_FETCHED,
});

export const agentCCPLogsReceived = (logs, recordCount) => ({
  type: AGENT_CCP_LOGS_RECEIVED,
  logs: logs,
  recordCount: recordCount,
});

export const errorAgentCCPLogsRetrieval = () => ({
  type: ERROR_AGENT_CCP_LOGS_RETRIEVAL,
});

export const fetchAgentCCPLogs = (criteria) => {
  return (dispatch) => {
    dispatch(showProgress());
    return getAgentCCPLogs(criteria)
      .then((resp) => {
        const recCount = Number(resp.headers["recordcount"]);
        dispatch(agentCCPLogsReceived(resp.data, recCount));
      })
      .catch(() => {
        dispatch(errorAgentCCPLogsRetrieval());
      });
  };
};

export const getAgentCCPLogs = (criteria) => {
  return axiosProxy
    .getInstance()
    .get(
      "/groups/" +
        auth.getCurrentGroupId() +
        "/ccpLogs?criteria=" +
        encodeURIComponent(JSON.stringify(criteria))
    );
};
