import React from "react";
import { FEATURES, featureToggles } from "../../auth/FearureToggles";
import {
  auth,
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER,
  USER_ROLE_VIEWER
} from "../../auth/Auth";
import {
  ExpandMoreRounded,
  CheckCircleOutlineRounded
} from "@material-ui/icons";
import { SWITCH_GROUP_SUCCESSFUL } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchConfig } from "../../actions/config";
import { openGroupSwitch } from "../../actions/groups";
import { withRouter } from "react-router-dom";
import { expandSidebar, collapseSidebar } from "../../actions/page";

import { withFeatureFlagsConsumer } from "../../FeatureFlags";
import { Sidebar, Menu, Badge } from "@operata/adagio";
import initials from "./initials";
import { getDashboardSlug } from "../../utils/dashboardMap";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      config: {}
    };
  }

  handleSelect = option => {
    if (option.url) {
      this.props.history.push(option.url);
    }
    this.props.collapseSidebar();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.authStatus === SWITCH_GROUP_SUCCESSFUL) {
      const { currentGroupId } = nextProps.userProfile;
      window.location.replace("/" + currentGroupId + "/dashboard");
    } else if (nextProps.config && nextProps.config.key) {
      this.setState({
        config: {
          ...this.state.config,
          [nextProps.config.key]: nextProps.config.value
        }
      });
    }
  }

  componentDidMount() {
    this.props.fetchConfig("HEARTBEAT_ENABLED", "boolean");
    this.props.fetchConfig("BI_ENABLED", "boolean");
  }

  handleLogout(event) {
    auth.logout();
    event.preventDefault();
  }

  enhanceOptionsWithSelectedState = options => {
    const { location } = this.props;
    const authCurrentGroupId = auth.getCurrentGroupId();

    // We just want the relevant part of the URL
    const lastPathSegment = location.pathname.split("/").pop();

    return options.map(option => {
      if (option.items) {
        return {
          ...option,
          items: option.items.map(item => ({
            ...item,
            selected:
              lastPathSegment ===
              getDashboardSlug(item.value, authCurrentGroupId)
          }))
        };
      }
      return {
        ...option,
        selected:
          lastPathSegment === getDashboardSlug(option.value, authCurrentGroupId)
      };
    });
  };

  render() {
    let { groups } = this.props.userProfile;
    const { location } = this.props;
    const isSelected = location.pathname.includes("/canvas");
    const authCurrentGroupId = auth.getCurrentGroupId();

    groups = groups.map(group => ({ ...group, icon: initials(group.name) }));

    const currentGroup = groups.find(({ id }) => id === authCurrentGroupId);
    const profilePicture = auth.getPicture();

    const profile = {
      ...this.props.userProfile,
      currentGroupId: authCurrentGroupId,
      currentGroup,
      picture: profilePicture
    };

    this.props.ldClient && this.props.ldClient.setStreaming();
    this.props.ldClient &&
      this.props.ldClient.identify({
        kind: "multi",
        user: {
          key: profile.id,
          name: profile.name,
          email: profile.email
        },
        organization: {
          key: profile.currentGroup?.id,
          name: profile.currentGroup?.name
        }
      });

    profile.icon = <img alt={profile.name} src={profilePicture} width="100%" />;

    const playbookOptions = [
      {
        label: "Overview",
        value: "default",
        alias: "Playbook #1",
        url: `/${authCurrentGroupId}/canvas/default`
      },
      {
        groupTitle: "Technology",
        items: [
          {
            label: "Networks, Applications and Equipment",
            value: "technology",
            alias: "Playbook #3",
            url: `/${authCurrentGroupId}/canvas/technology`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-networks-applications-and-equipment"
          },
          {
            label: "Reported Issues and Insights",
            value: "issues",
            alias: "Playbook #7",
            url: `/${authCurrentGroupId}/canvas/issues`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-reported-issues-and-insights"
          },
          {
            label: "Call Overview",
            value: "calloverview",
            alias: "Playbook #7",
            url: `/${authCurrentGroupId}/canvas/calloverview`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-call-overview"
          },
          {
            label: "Agent Overview",
            value: "agentoverview",
            alias: "Playbook #4",
            url: `/${authCurrentGroupId}/canvas/agentoverview`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-agent-overview"
          },
          {
            label: "Cohort Comparison",
            value: "comparison",
            alias: "Playbook #6",
            url: `/${authCurrentGroupId}/canvas/comparison`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-cohort-comparison"
          },
          {
            label: "Logs and Errors",
            value: "logserrors",
            alias: "Playbook #5",
            url: `/${authCurrentGroupId}/canvas/logserrors`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-logs-and-errors"
          },
          {
            label: "Collection Coverage",
            value: "coverage",
            url: `/${authCurrentGroupId}/canvas/coverage`,
            badge: <Badge tone="identifier">Admin</Badge>,
            new: true,
            analytics: "canvas-collection-coverage"
          }
        ]
      },
      {
        groupTitle: "Operations",
        items: [
          {
            label: "Agent Interactions",
            value: "agentinteractions",
            alias: "Playbook #2",
            url: `/${authCurrentGroupId}/canvas/agentinteractions`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-agent-interactions"
          },
          {
            label: "Customer Experience",
            value: "customerexperience",
            alias: "Playbook #8",
            url: `/${authCurrentGroupId}/canvas/customerexperience`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-customer-experience"
          },
          {
            label: "Conversational Analytics",
            value: "conversationanalytics",
            alias: "Playbook #9",
            url: `/${authCurrentGroupId}/canvas/conversationanalytics`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-conversational-analytics"
          }
        ]
      },
      {
        label: "All Dashboards",
        value: "list",
        url: `/${authCurrentGroupId}/canvas/list`,
        analytics: "canvas-all-dashboards"
      }
    ];

    const enhancedOptions = this.enhanceOptionsWithSelectedState(
      playbookOptions
    );

    return (
      <Sidebar.Layout>
        <Sidebar.Logo
          isExpanded={this.props.sidebarExpanded}
          expandSidebar={this.props.expandSidebar}
          collapseSidebar={this.props.collapseSidebar}
        />
        <Sidebar.Dropdown.Layout
          isExpanded={this.props.sidebarExpanded}
          currentGroup={currentGroup}
          selectGroup={({ id }) => auth.switchGroup(id)}
          groups={groups}
          expandIcon={<ExpandMoreRounded />}
          selectedIcon={<CheckCircleOutlineRounded />}
        />
        {auth.hasPermission([
          USER_ROLE_USER,
          USER_ROLE_ADMIN,
          USER_ROLE_SUPER
        ]) && (
          <Sidebar.Section isExpanded={this.props.sidebarExpanded}>
            <Sidebar.Navigation.MenuItem
              isExpanded={this.props.sidebarExpanded}
              exact
              path={`/${authCurrentGroupId}/dashboard`}
              icon={<Sidebar.HomeIcon />}
              name="Home"
            />
            {this.state.config["BI_ENABLED"] === "true" && (
              <Menu
                anchor="sidebar"
                options={enhancedOptions}
                analytics={"canvas-menu"}
                trigger={
                  <Sidebar.MenuItem
                    submenu
                    isExpanded={this.props.sidebarExpanded}
                    icon={<Sidebar.PlaybooksIcon />}
                    name="Canvas"
                    isSelected={isSelected}
                  />
                }
                onSelect={this.handleSelect}
              />
            )}
          </Sidebar.Section>
        )}
        <Sidebar.Navigation isExpanded={this.props.sidebarExpanded}>
          <Sidebar.Navigation.Menu>
            {this.props &&
              this.props.flags.embeddedCanvasApi &&
              auth.hasPermission([
                USER_ROLE_VIEWER,
                USER_ROLE_ADMIN,
                USER_ROLE_SUPER
              ]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/playbooks`}
                  icon={<Sidebar.PlaybooksIcon />}
                  name="Dashboards"
                  onClick={this.handleSelect}
                />
              )}
          </Sidebar.Navigation.Menu>

          {auth.hasPermission([
            USER_ROLE_USER,
            USER_ROLE_ADMIN,
            USER_ROLE_SUPER
          ]) && (
            <Sidebar.Navigation.Menu>
              <Sidebar.Navigation.MenuTitle
                isExpanded={this.props.sidebarExpanded}
              >
                Explorer
              </Sidebar.Navigation.MenuTitle>
              <Sidebar.Navigation.MenuItem
                isExpanded={this.props.sidebarExpanded}
                path={`/${authCurrentGroupId}/explorer`}
                icon={<Sidebar.SearchCallIcon />}
                name="Calls and Logs"
              />
            </Sidebar.Navigation.Menu>
          )}

          {this.state.config["HEARTBEAT_ENABLED"] === "true" &&
            auth.hasPermission([
              USER_ROLE_SUPER,
              USER_ROLE_ADMIN,
              USER_ROLE_USER
            ]) && (
              <Sidebar.Navigation.Menu>
                <Sidebar.Navigation.MenuTitle
                  isExpanded={this.props.sidebarExpanded}
                >
                  Heartbeat
                </Sidebar.Navigation.MenuTitle>
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  exact
                  path={`/${authCurrentGroupId}/gsm/heartbeat/insights`}
                  icon={<Sidebar.HeartbeatIcon />}
                  name="Heartbeat"
                />
                {auth.hasPermission([USER_ROLE_SUPER, USER_ROLE_ADMIN]) && (
                  <Sidebar.Navigation.MenuItem
                    isExpanded={this.props.sidebarExpanded}
                    exact
                    path={`/${authCurrentGroupId}/gsm/heartbeat`}
                    icon={<Sidebar.ExperimentIcon />}
                    name="Tests"
                  />
                )}

                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/gsm/heartbeat/logs`}
                  icon={<Sidebar.ReportIcon />}
                  name="Logs"
                />
              </Sidebar.Navigation.Menu>
            )}

          {auth.hasPermission([
            USER_ROLE_USER,
            USER_ROLE_ADMIN,
            USER_ROLE_SUPER
          ]) && (
            <Sidebar.Navigation.Menu>
              <Sidebar.Navigation.MenuTitle
                isExpanded={this.props.sidebarExpanded}
              >
                Tools
              </Sidebar.Navigation.MenuTitle>

              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/workflows`}
                  icon={<Sidebar.WorkflowIcon />}
                  name="Workflow"
                />
              )}

              {featureToggles.isFeatureEnabled(FEATURES.SIMULATE_MODULE) &&
                auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                  <Sidebar.Navigation.MenuItem
                    isExpanded={this.props.sidebarExpanded}
                    path={`/${authCurrentGroupId}/campaigns`}
                    icon={<Sidebar.SimulateIcon />}
                    name="Simulate"
                  />
                )}

              {auth.hasPermission([
                USER_ROLE_USER,
                USER_ROLE_ADMIN,
                USER_ROLE_SUPER
              ]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/history/lists`}
                  icon={<Sidebar.HistoryIcon />}
                  name="History"
                />
              )}
            </Sidebar.Navigation.Menu>
          )}

          {this.props.sidebarExpanded && (
            <Sidebar.Navigation.Menu pinToBottom>
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/users`}
                  icon={<Sidebar.TeamIcon />}
                  name="Teammates"
                />
              )}
              {auth.hasPermission([
                USER_ROLE_ADMIN,
                USER_ROLE_SUPER,
                USER_ROLE_USER
              ]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/configs`}
                  icon={<Sidebar.SettingsIcon />}
                  name="Group Settings"
                />
              )}
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/extutil`}
                  icon={<Sidebar.CoverageIcon />}
                  name="Coverage"
                />
              )}
              {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/billing`}
                  icon={<Sidebar.UsageIcon />}
                  name="Usage"
                />
              )}
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Sidebar.Navigation.MenuItem
                  isExpanded={this.props.sidebarExpanded}
                  path={`/${authCurrentGroupId}/admin/groups`}
                  icon={<Sidebar.GroupIcon />}
                  name="Groups"
                />
              )}
            </Sidebar.Navigation.Menu>
          )}
        </Sidebar.Navigation>
        <Sidebar.StickyMenu.Layout>
          <Sidebar.StickyMenu.Profile
            isExpanded={this.props.sidebarExpanded}
            profile={profile}
            handleLogout={this.handleLogout}
            expandIcon={<ExpandMoreRounded />}
          />
        </Sidebar.StickyMenu.Layout>
      </Sidebar.Layout>
    );
  }
}

MainSidebar.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
  openGroupSwitch: PropTypes.func.isRequired,
  expandSidebar: PropTypes.func.isRequired,
  collapseSidebar: PropTypes.func.isRequired,
  authStatus: PropTypes.string,
  userProfile: PropTypes.object,
  config: PropTypes.object,
  sidebarExpanded: PropTypes.bool,
  flags: PropTypes.object,
  ldClient: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};

const mapDispatchToProps = {
  fetchConfig,
  openGroupSwitch,
  expandSidebar,
  collapseSidebar
};

const mapStateToProps = state => ({
  authStatus: state.auth.switchGroupStatus,
  userProfile: state.auth.profile,
  config: state.config.config,
  sidebarExpanded: state.page.sidebarExpanded
});

// eslint-disable-next-line no-class-assign
MainSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSidebar);

// eslint-disable-next-line no-class-assign
MainSidebar = withFeatureFlagsConsumer()(MainSidebar);

export default withRouter(MainSidebar);
