import moment from "moment-timezone";
import { useState, useEffect } from "react";

export const getTimestampCriteria = timestampFilterValue => {
  let criteria = {};

  const fromAndToTime = timestampFilterValue.match(
    /(time:\(from:)(.*)(,to:)(.*)(\))/
  );
  criteria["fromTime"] = fromAndToTime[2].replace(/'/g, "");
  criteria["toTime"] = fromAndToTime[4].replace(/'/g, "");
  criteria["timezone"] = moment.tz.guess();

  return criteria;
};

export const sortData = (key, order = "desc") => {
  return (a, b) => {
    let valueA = key ? getNestedValue(a, key) : a;
    let valueB = key ? getNestedValue(b, key) : b;

    const typeA = getType(valueA);
    const typeB = getType(valueB);

    if (typeA !== typeB) {
      console.log(
        "[Operata Debug] Type mismatch in sorting",
        key,
        valueA,
        valueB
      );
    }

    if (key === "timestamp" || typeA === "date") {
      valueA = new Date(valueA).getTime();
      valueB = new Date(valueB).getTime();
    } else if (typeA === "number") {
      valueA = Number(valueA);
      valueB = Number(valueB);
    } else if (typeA === "string") {
      valueA = valueA.toString().toLowerCase();
      valueB = valueB.toString().toLowerCase();
    }

    if (order === "desc") {
      return valueA < valueB ? 1 : -1;
    } else {
      return valueA > valueB ? 1 : -1;
    }
  };
};

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const getType = value => {
  if (value === "") return "string";
  if (!isNaN(value) && value !== "") return "number";

  const parsedDate = new Date(value);
  if (parsedDate instanceof Date && !isNaN(parsedDate)) {
    return "date";
  }

  return "string";
};

export const Paginate = ({ pageSize, page }) => array =>
  array.slice(pageSize * page, pageSize * (page + 1));

export const useDebounce = (value, delay, isReset) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      if (isReset) {
        setDebouncedValue(value); // Bypass debounce and set immediately
        return;
      }

      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, isReset]
  );

  return debouncedValue;
};
