import {
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const genesysConfig: ConfigField[] = [
  {
    type: `json`,
    key: `GENESYS`,
    title: `Genesys Softphone Configuration`,
    description: `Genesys Softphone Configuration`,
    fields: [
      { type: `string`, key: `CCAAS_ID`, title: "Org Id", default: `` },
      { type: `string`, key: `CCAAS_NAME`, title: "Org Name", default: `` },
      {
        type: `string`,
        key: `EXTERNAL_CLIENT_ID`,
        title: "Client Id",
        default: ``,
      },
      {
        type: `string`,
        key: `EXTERNAL_CLIENT_SECRET`,
        title: "Client Secret",
        default: ``,
      },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
];
