import {
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const softphoneConfig: ConfigField[] = [
  {
    type: `string`,
    key: `AWS_CONNECT_CCP`,
    title: `Amazon Connect CCP`,
    fieldText: `Amazon Connect CCP`,
    description: `The Customers Amazon Connect CCP Access URL. Correct format should be https://[instance name].awsapps.com/connect/ccp or https://[instance name].connect.aws/ccp or https://[instance name].connect.aws/connect/ccp`,
    default: "",
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    hide: true,
  },
  {
    type: `list`,
    key: `SOFTPHONE_TYPE`,
    title: `Media Handled by Custom Softphone?`,
    fieldText: `Media Handled by Custom Softphone?`,
    description: `Select if the media is handled by the native Amazon Connect CCP`,
    default: "NATIVE",
    options: ["NATIVE", "CUSTOM", "AUTO"],
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_USER, USER_ROLE_ADMIN],
    helperTexts: {
      NATIVE:
        "Select this option when the native softphone handles the media. This should be the default, unless the softphone has specifically been configured to handle audio in the parent window.",
      CUSTOM:
        "Select this option when the softphone implementation is custom, and the media is being tunnelled through the parent page.",
      AUTO:
        "[Beta] A new configuration option to automatically detect whether the softphone is native or custom.",
    },
  },
  {
    type: `boolean`,
    key: `MESSENGER_IN_CCP`,
    title: `Load Agent Messenger in Native Amazon softphone?`,
    fieldText: `Load Agent Messenger in Native Amazon softphone?`,
    description: `Disable this if the Amazon CCP is hosted inside another page/Iframe`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `array`,
    key: `SOFTPHONE_WHITELISTED_ORIGINS`,
    title: `Approved Origins`,
    fieldText: `Approved Origins`,
    description: `Add the origin(s) (scheme + host + port) of the Customer Softphone(s)`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
  },
  {
    type: `string`,
    key: `SOFTPHONE_OPERATA_API_SERVER`,
    title: `Operata API Server.`,
    fieldText: `Operata API Server.`,
    description: `Operata API Server. Eg: https://api.operata.io`,
    default: `https://api.operata.io`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
    hide: true,
  },
  {
    type: `string`,
    key: `SOFTPHONE_OPERATA_STATIC_SERVER`,
    title: `Operata Static Server`,
    fieldText: `Operata Static Server`,
    description: `Operata Static Server. Eg: https://static.operata.io`,
    default: `https://static.operata.io/`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
    hide: true,
  },
  {
    type: `list`,
    key: `SOFTPHONE_CONNECT_RTC_VERSION`,
    title: `Connect RTC Version`,
    fieldText: `Connect RTC Version`,
    description: `Version of the connect rtc library. Eg: v1.1.7.`,
    default: `v1.1.7`,
    options: [`v1.1.7`, `v1.1.6`, `v1.1.5`, `v1.1.4`],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    hide: true,
  },
  {
    type: `list`,
    key: `SOFTPHONE_CONNECT_STREAMS_VERSION`,
    title: `Connect Streams`,
    fieldText: `Connect Streams`,
    description: `Version of the connect streams library. Eg: 1.5.1.`,
    default: `1.5.1`,
    options: [`1.5.1`, `1.5.0`, `1.4.9`, `1.4.8`],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    hide: true,
  },
  {
    type: `boolean`,
    key: `SOFTPHONE_LOAD_AGENT_MESSENGER`,
    title: `Load Agent Messenger?`,
    fieldText: `Load Agent Messenger?`,
    description: `Load Agent Messenger?.`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `boolean`,
    key: `SOFTPHONE_LOAD_NEXT_MESSENGER`,
    title: `Load Next Messenger?`,
    fieldText: `Load Next Messenger?`,
    description: `Whether to load the new design of the agent messenger.`,
    default: false,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `list`,
    key: `SOFTPHONE_CCP_LOG_COLLECTION`,
    title: `CCP Log Collection`,
    fieldText: `CCP Log Collection`,
    description: `Enable CCP log collection on a specific log level`,
    default: "",
    options: [
      { value: "", label: "OFF" },
      "TRACE",
      "DEBUG",
      "INFO",
      "LOG",
      "WARN",
      "ERROR",
      "CRITICAL",
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `string`,
    key: `SOFTPHONE_SSO_LOGIN_URL`,
    title: `SSO Login URL`,
    fieldText: `SSO Login URL`,
    description: `SSO Login URL (Optional).`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `list`,
    key: `REGIONAL_AGENT_PROVIDER`,
    title: `Virtual Agent SSO Provider`,
    fieldText: `Virtual Agent SSO Provider`,
    description: `The SSO provider that the Virtual Agent will be configured to use for login. Turned off by default`,
    default: "",
    options: [{ value: "", label: "OFF" }, "OKTA", "AZURE"],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `boolean`,
    key: `SOFTPHONE_HEADSET_STATS_COLLECTION`,
    title: `Headset stats collection`,
    fieldText: `Enable headset stats collection?`,
    description: `Enable stats collection from Jabra headsets?`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `boolean`,
    key: `SOFTPHONE_JABRA_CALL_CONTROLS`,
    title: `Jabra device call controls`,
    fieldText: `Enable Jabra device call controls?`,
    description: `Disable this if you have a custom implementation for Jabra call controls`,
    default: true,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `boolean`,
    key: `SOFTPHONE_CALLER_ID`,
    title: `Interaction Metadata`,
    fieldText: `Customer Endpoint (CLI)`,
    description: `Enables additional call attributes to be attached to the Operata contact record and used within Operata Canvas for reporting`,
    default: false,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
  {
    type: `array`,
    key: `START_OF_DAY_TESTING_NUMBER`,
    title: `Start of day testing number`,
    fieldText: `Start of day testing number`,
    description: `Number used to run Star of Day test`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    default: ``,
  },
  {
    type: `string`,
    key: `START_OF_DAY_TESTING_STATUS`,
    title: `Start of day testing status`,
    fieldText: `Start of day testing status`,
    description: `Status of the agent to trigger Start of Day test`,
    default: `Start of Day Test`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
];
