import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";
import { CALL_TAG_CONFIG_KEY } from "../components/Configs/CallTaggingConfig";
import {
  CONFIG_RECEIVED,
  CONFIG_CREATED,
  ERROR_CONFIG_CREATED,
  ERROR_CONFIG_RECEIVED,
  RESET_CONFIG_PAGE_STATE,
} from "../reducers/config";

export const showProgress = () => ({
  type: SHOW_PROGRESS,
});

export const configReceived = (config) => ({
  type: CONFIG_RECEIVED,
  config: config,
});

export const errorConfigReceived = (message) => ({
  type: ERROR_CONFIG_RECEIVED,
  message: message,
});

export const configCreated = (config) => ({
  type: CONFIG_CREATED,
  config: config,
});

export const errorConfigCreated = (message) => ({
  type: ERROR_CONFIG_CREATED,
  message: message,
});

export const resetState = () => ({
  type: RESET_CONFIG_PAGE_STATE,
});

//redux-thunk
export const resetConfigsPageState = () => {
  return (dispatch) => {
    dispatch(resetState());
  };
};

export const fetchConfig = (key, type) => {
  return (dispatch) => {
    dispatch(showProgress());
    return getConfig(key, type)
      .then((resp) => {
        console.log("dispatching configReceived..");
        dispatch(configReceived(resp.data));
      })
      .catch(() => {
        dispatch(
          errorConfigReceived("An error occurred while retrieving configs")
        );
        dispatch(resetState());
      });
  };
};

const getConfig = (key, type) => {
  return axiosProxy.getInstance().get("/config/" + key + "/" + type);
};

export const createOrUpdateConfig = (config, type) => {
  return (dispatch) => {
    console.log(
      "dispatching config config requested..",
      JSON.stringify(config)
    );
    dispatch(showProgress());

    console.log("create config");
    return putConfig(config, type)
      .then((resp) => {
        console.log("dispatching config created..");
        dispatch(configCreated(resp.data));
        dispatch(resetState());
      })
      .catch(() => {
        dispatch(
          errorConfigCreated("An error occurred while setting the config")
        );
        dispatch(resetState());
      });
  };
};

const putConfig = (config, type) => {
  return axiosProxy.getInstance().put("/config/" + type, config);
};

export const validateConfig = (config) => {
  return axiosProxy.getInstance().post("/config/validate", config);
};

export const fetchDefaultTaggingConfig = () => {
  return (dispatch) => {
    dispatch(showProgress());
    return getDefaultTaggingConfig()
      .then((resp) => {
        dispatch(
          configReceived({
            key: CALL_TAG_CONFIG_KEY,
            value: resp.data,
          })
        );
      })
      .catch(() => {
        dispatch(
          errorConfigReceived("An error occurred while retrieving configs")
        );
        dispatch(resetState());
      });
  };
};

const getDefaultTaggingConfig = () => {
  return axiosProxy.getInstance().get("/tagging/tag-configs");
};
