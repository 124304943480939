import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const EVENT_SOURCE_CREATED = "EVENT_SOURCE_CREATED";
export const ERROR_EVENT_SOURCE_CREATED = "ERROR_EVENT_SOURCE_CREATED";
export const EVENT_SOURCE_CONFIG_RECEIVED = "EVENT_SOURCE_CONFIG_RECEIVED";
export const EVENT_SOURCE_DELETED = "EVENT_SOURCE_DELETED";
export const ERROR_EVENT_SOURCE_FETCH = "ERROR_EVENT_SOURCE_FETCH";
export const ERROR_EVENT_SOURCE_DELETED = "ERROR_EVENT_SOURCE_DELETED";

// Sets the loading state to true for the global progress indicator
export const eventSourceActionRequested = () => ({
  type: SHOW_PROGRESS
});

const postEventSource = eventSource => {
  return axiosProxy.getInstance().post("/users/eventBridge", eventSource);
};

export const handlePostEventSource = eventSource => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    return postEventSource(eventSource)
      .then(resp => {
        dispatch(eventSourceCreated(resp.data));
        // Refresh the event source config table
        dispatch(fetchEventSourceConfig(true));
      })
      .catch(error => {
        let errorData =
          "Unexpected error occurred while creating EventBridge source.";
        let errorMessage =
          "Error - An unexpected error occurred while creating EventBridge sources.";
        if (error.response && error.response.status >= 400) {
          errorData = error.response.data;
          errorMessage =
            "Error - " + getCreateEventBridgeErrorMessage(error.response.data);
        }
        dispatch(errorEventSourceCreated(errorData));

        return Promise.reject({
          message: errorMessage
        });
      });
  };
};

export const eventSourceCreated = eventSource => ({
  type: EVENT_SOURCE_CREATED,
  eventSource: eventSource
});

export const errorEventSourceCreated = message => ({
  type: ERROR_EVENT_SOURCE_CREATED,
  message: message
});

export const errorEventSourceFetch = message => ({
  type: ERROR_EVENT_SOURCE_FETCH,
  message: message
});

export const errorEventSourceDeleted = message => ({
  type: ERROR_EVENT_SOURCE_DELETED,
  message: message
});

export const eventSourceConfigReceived = eventSourceConfig => ({
  type: EVENT_SOURCE_CONFIG_RECEIVED,
  eventSourceConfig: eventSourceConfig
});

const getEventSourceConfig = fetchStatus => {
  return axiosProxy
    .getInstance()
    .get("/users/eventBridge/" + fetchStatus.toString());
};

export const fetchEventSourceConfig = fetchStatus => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    return getEventSourceConfig(fetchStatus)
      .then(resp => {
        dispatch(eventSourceConfigReceived(resp.data));
      })
      .catch(error => {
        let errorData =
          "Unexpected error occurred while fetching EventBridge sources.";
        if (error.response && error.response.status >= 400) {
          errorData = error.response.data;
        }
        dispatch(errorEventSourceFetch(errorData));
      });
  };
};

export const eventSourceDeleted = () => ({
  type: EVENT_SOURCE_DELETED
});

export const deleteEventSource = eventSource => {
  return dispatch => {
    dispatch(eventSourceActionRequested());
    return delEventSource(eventSource[`eventSourceId`])
      .then(() => {
        dispatch(eventSourceDeleted());
        // Refresh the event source config table
        dispatch(fetchEventSourceConfig(true));
      })
      .catch(error => {
        let errorData =
          "Unexpected error occurred while fetching EventBridge sources.";
        if (error.response && error.response.status >= 400) {
          errorData = error.response.data;
        }
        dispatch(errorEventSourceFetch(errorData));
        return Promise.reject(error);
      });
  };
};

const delEventSource = id => {
  return axiosProxy.getInstance().delete("/users/eventBridge/" + id);
};

// Returns the user facing error message for create event source errors returned by the API
export function getCreateEventBridgeErrorMessage(apiMessage) {
  let errorMessage =
    "An error occurred while creating event source. Please try again.";
  if (apiMessage !== undefined && JSON.stringify(apiMessage).length > 0) {
    const apiMessageString = JSON.stringify(apiMessage);
    if (
      apiMessageString.includes(
        "at 'name' failed to satisfy constraint: Member must satisfy regular expression pattern:"
      )
    ) {
      errorMessage =
        "Event source name should contain only the following characters (/[\\.\\-_A-Za-z0-9]+){2,}. Please check your name and try again.";
    } else if (
      apiMessageString.includes(
        "Caller is not allowlisted for the event source partner namespace"
      )
    ) {
      errorMessage =
        "Invalid AWS Account (Caller is not allowlisted for the event source partner namespace). Please check your account number and try again.";
    } else if (apiMessageString.includes("ResourceAlreadyExistsException")) {
      errorMessage =
        "Event source name already exists with given AWS account. Please try again with a unique name.";
    }
  }
  return errorMessage;
}
