import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const BILLING_RECEIVED = "BILLING_RECEIVED";
export const ERROR_BILLING_LOGS = "ERROR_BILLING_LOGS";
export const BILLING_LOGS_RECEIVED = "BILLING_LOGS_RECEIVED";
export const MARKETPLACE_DATA_RECEIVED = "MARKETPLACE_DATA_RECEIVED";
export const ERROR_MARKETPLACE_BILLING = "ERROR_MARKETPLACE_BILLING";
export const BILLING_SUBSCRIPTIONS_RECEIVED = "BILLING_SUBSCRIPTIONS_RECEIVED";
export const ERROR_BILLING_SUBSCRIPTIONS = "ERROR_BILLING_SUBSCRIPTIONS";

export type BillingCriteria = {
  fromTime: moment.Moment | string;
  toTime: moment.Moment | string;
  timezone: string;
};

export const billingActionRequested = () => ({
  type: SHOW_PROGRESS,
});

export const billingReceived = (billingDetails: any) => ({
  type: BILLING_RECEIVED,
  billing: billingDetails,
});

export const errorBillingDetails = () => ({
  type: ERROR_BILLING_LOGS,
  message: "Error fetching billing details",
});

export const billingAuditLogsReceived = (auditLogs: any) => ({
  type: BILLING_LOGS_RECEIVED,
  data: auditLogs,
});

export const marketplaceDataReceived = (data: any) => ({
  type: MARKETPLACE_DATA_RECEIVED,
  marketplaceData: data,
});

export const errorMarketplaceData = () => ({
  type: ERROR_MARKETPLACE_BILLING,
  message: "Error fetching marketplace data",
});

export const billingSubscriptionsReceived = (data: any) => ({
  type: BILLING_SUBSCRIPTIONS_RECEIVED,
  subscriptions: data,
});

export const errorBillingSubscriptions = () => ({
  type: ERROR_BILLING_SUBSCRIPTIONS,
  message: "Error fetching marketplace data",
});

export const fetchGroupBilling = (criteria: BillingCriteria) => {
  return (dispatch: any) => {
    dispatch(billingActionRequested());
    return getBillingDetails(criteria)
      .then((resp) => {
        dispatch(billingReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorBillingDetails());
      });
  };
};

export const fetchBillingAuditLogs = () => {
  return (dispatch: any) => {
    dispatch(billingActionRequested());
    return getBillingAuditLogs()
      .then((resp) => {
        dispatch(billingAuditLogsReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorBillingDetails());
      });
  };
};

export const fetchMarketplaceData = () => {
  return (dispatch: any) => {
    dispatch(billingActionRequested());
    return getMonthlyBillingData()
      .then((resp) => {
        dispatch(marketplaceDataReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorMarketplaceData());
      });
  };
};

const getBillingDetails = (criteria: any) => {
  return axiosProxy
    .getInstance()
    .get("/billing?criteria=" + JSON.stringify(criteria));
};

const getBillingAuditLogs = () => {
  return axiosProxy.getInstance().get("/marketplace/billingAuditLogs");
};

const getMonthlyBillingData = () => {
  return axiosProxy
    .getInstance()
    .get("/marketplace/customerMarketplaceBilling");
};
