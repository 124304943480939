import React, { useState, useEffect } from "react";
import {
  ButtonRefactored as Button,
  Stack,
  Modal,
  Typography,
} from "@operata/adagio";

type ModalDeleteEventBridgeProps = {
  onClose: () => void;
  handleSubmit: (eventSourceId: string) => void;
};

function ModalDeleteEventBridge({
  onClose,
  handleSubmit,
}: ModalDeleteEventBridgeProps) {
  return (
    <Modal isOpen onClose={onClose} size="micro">
      <Modal.Header border>Confirm Delete</Modal.Header>
      <Modal.Content>
        <Stack direction="column" gap="medium" padding="medium" block>
          <Typography>
            <p>Are you sure you want to delete this EventBridge Source?</p>
            <p>This action is irreversable once taken</p>
          </Typography>
        </Stack>
      </Modal.Content>
      <Modal.Footer background>
        <Stack direction="row" gap="tiny" justify="end">
          <Button onClick={onClose} type="tertiary" size="medium">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            tone="danger"
            size="medium"
          >
            Delete
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDeleteEventBridge;
