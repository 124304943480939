import React, { useState } from "react";
import {
  RouteComponentProps,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  Stack,
  Section,
  Tabs,
  PageHeading,
  ButtonRefactored as Button,
  AddIcon,
} from "@operata/adagio";

import GroupList from "./GroupList";
import { BillingTS } from "../Billing/Billing";
import Marketplace from "../Billing/Marketplace";
import BillingAuditLogs from "../Billing/BillingAuditLogs";
import { auth, USER_ROLE_SUPER } from "../../auth/Auth";
import styled from "@emotion/styled";

const AllowOverflow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 15vh;
`;

interface TabContainerProps {
  children: React.ReactNode;
}

const TabContainer: React.FC<TabContainerProps> = ({ children }) => (
  <div>{children}</div>
);

type GroupListTabsProps = RouteComponentProps;

const GroupListTabs: React.FC<GroupListTabsProps> = ({ history, match }) => {
  const [value, setValue] = useState<number>(0);
  const { path } = match;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = () => {
    history.push("/admin/group/0");
  };

  const hasPermission = auth.hasPermission([USER_ROLE_SUPER]);

  return (
    <AllowOverflow>
      <Stack direction={"column"} gap="24" padding="medium">
        <PageHeading title="Group Management">
          <Button
            type="primary"
            size="large"
            iconAfter={<AddIcon />}
            onClick={handleClick}
          >
            Provision New Group
          </Button>
        </PageHeading>
        <Section>
          <Tabs padding="16">
            <Tabs.Tab exact to={path} label="Active Groups" />
            <Tabs.Tab to={`${path}/archived`} label="Archived Groups" />
            {hasPermission && (
              <>
                <Tabs.Tab to={`${path}/billing`} label="Billing" />
                <Tabs.Tab
                  to={`${path}/billingauditlogs`}
                  label="Billing Audit Logs"
                />
                <Tabs.Tab to={`${path}/marketplace`} label="Marketplace" />
              </>
            )}
          </Tabs>

          <Section.Content padding="medium">
            <div style={{ minHeight: "50em" }}>
              <Switch>
                <Route exact path={path}>
                  <GroupList type="active" />
                </Route>
                <Route path={`${path}/archived`}>
                  <GroupList type="archived" />
                </Route>
                {hasPermission && (
                  <>
                    <Route path={`${path}/billing`}>
                      <BillingTS />
                    </Route>
                    <Route path={`${path}/billingauditlogs`}>
                      <BillingAuditLogs />
                    </Route>
                    <Route path={`${path}/marketplace`}>
                      <Marketplace loading={false} />
                    </Route>
                  </>
                )}
              </Switch>
            </div>
          </Section.Content>
        </Section>
      </Stack>
    </AllowOverflow>
  );
};

export default withRouter(GroupListTabs);
