import styled from "@emotion/styled";

const PaddingSides = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 15vh;

  padding: 0 20px;
`;

const RemoveMarginTop = styled.div`
  margin-top: 0px !important;
  padding-bottom: 20px;
  margin-bottom: 40px;
`;

const styles = {
  "&& .MuiPaper-root": {
    boxShadow: "none !important",
    borderBottom: "none !important",
  },

  "& .datatable": {
    boxShadow: "none !important",
    borderBottom: "none !important",
  },

  "& .MuiPaper-elevation4": {
    boxShadow: "none !important",
    borderBottom: "none !important",
  },

  "& .MuiPaper-rounded": {
    boxShadow: "none !important",
    borderBottom: "none !important",
  },

  "& .MuiTable-root": {
    boxShadow: "none !important",
    borderBottom: "none !important",
  },
};

export const StyledWrapper = styled.div`
  ${Object.entries(styles).map(
    ([key, value]) => `
    ${key} {
      box-shadow: ${value.boxShadow};
      border-bottom: ${value.borderBottom};
    }
  `
  )};
`;
