import {
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const simulateConfig: ConfigField[] = [
  {
    type: `string`,
    key: `TWILIO_SID`,
    title: `Dialler SID`,
    fieldText: `Dialler SID`,
    description: `Dialler is used to initiate inbound calls to your Amazon Connect Contact Flow`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_ADMIN],
    default: ``,
  },
  {
    type: `string`,
    key: `TWILIO_TOKEN`,
    title: `Twilio Token`,
    fieldText: `Twilio Token`,
    description: `The Twilio Token to allow integration to the Twilio Account SID`,
    write: [USER_ROLE_SUPER],
    read: [],
    default: ``,
  },
  {
    type: `string`,
    key: `TWILIO_CPS`,
    title: `Calls Per Second`,
    fieldText: `Calls Per Second`,
    description: `The purchased maximum calls per second`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `array`,
    key: `SIMULATE_WHITELISTED_TO_NUMBERS`,
    title: `Allowlisted 'To' Numbers`,
    fieldText: `Allowlisted 'To' Numbers`,
    description: `The phone numbers that can be dialled by a Simulate campaign`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
];
