import { USER_ROLE_SUPER } from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const heartbeatConfig: ConfigField[] = [
  {
    type: `boolean`,
    key: `HEARTBEAT_ENABLED`,
    title: `Enable Heartbeat?`,
    fieldText: `Enable Heartbeat?`,
    description: `Enabling Heartbeat will load the Heartbeat/Push Service client on agent softphones`,
    default: false,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
  },
  {
    type: `array`,
    key: `HEARTBEAT_TARGET_PHONE_NUMBERS`,
    title: `Heartbeat 'Target Phone Number'`,
    fieldText: `Heartbeat 'Target Phone Number'`,
    description: `The phone number that Heartbeat will dial when running a test`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
    default: ``,
  },
  {
    type: `array`,
    key: `HEARTBEAT_VIRTUAL_AGENT_NAMES`,
    title: `Heartbeat 'Virtual Agent Names'`,
    fieldText: `Heartbeat 'Virtual Agent Names'`,
    description: `The comma separated names of the virtual agents that are used to run Heartbeat tests`,
    write: [USER_ROLE_SUPER],
    read: [USER_ROLE_SUPER],
    default: ``,
  },
];
