/**
 * Finds the label corresponding to a given value in an array of form options.
 *
 * The array can contain objects with `value` and `label` properties or strings.
 * For entries that are strings, the label and value are the same.
 * For example [ { value: "", label: "OFF" }, "TRACE", "DEBUG"]
 *
 * @param {Array} array - The array of form options to search through.
 * @param {string} value - The value to search for.
 * @returns {string|null} - The corresponding label if found, otherwise null.
 */
export function findFormLabelByValue(
  array: Array<string | { value: string; label: string }>,
  value: string
): string | null {
  const item = array.find((item) => {
    if (typeof item === "object") {
      return item.value === value;
    } else if (typeof item === "string") {
      return item === value;
    }
    return false;
  });

  if (item) {
    return typeof item === "object" ? item.label : item;
  }
  return null;
}
