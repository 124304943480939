import React from "react";

import { ConfigsSideBar } from "./ConfigsSidebar";
import { ConfigScroll } from "./ConfigScroll";
import { FEATURES, featureToggles } from "../../auth/FearureToggles";
import { auth, USER_ROLE_ADMIN, USER_ROLE_SUPER } from "../../auth/Auth";
import { Stack } from "@operata/adagio";

function getAvailableConfigs(configs: Record<string, boolean>) {
  const availableConfigs = [];

  for (const config in configs) {
    if (configs[config]) {
      availableConfigs.push(config);
    }
  }
  return availableConfigs;
}

const getEnabledConfigs = (
  auth: typeof import("../../auth/Auth").auth,
  featureToggles: typeof import("../../auth/FearureToggles").featureToggles
): Record<string, boolean> => {
  return {
    SOFTPHONE_MODULE: true,
    GENESYS_MODULE: true,
    NICE_MODULE: true,
    SIMULATE_MODULE: featureToggles.isFeatureEnabled(FEATURES.SIMULATE_MODULE),
    API_MODULE: auth.hasPermission([USER_ROLE_SUPER, USER_ROLE_ADMIN]),
    GSM_MODULE: auth.hasPermission([USER_ROLE_SUPER]),
    HEARTBEAT_MODULE: auth.hasPermission([USER_ROLE_SUPER]),
    EVENTBRIDGE_MODULE: featureToggles.isFeatureEnabled(
      FEATURES.EVENTBRIDGE_MODULE
    ),
    DATA_SOURCES_MODULE: true,
    AGENT_ALERTS_MODULE: true,
  };
};

export const Configs = () => {
  const configs = getEnabledConfigs(auth, featureToggles);
  const availableConfigs = getAvailableConfigs(configs);

  return (
    <Stack direction={"column"} height="100vh" width="100%">
      <Stack flex={true} direction="row" width="100%" height="100%">
        <ConfigsSideBar availableConfigs={availableConfigs} />
        <ConfigScroll />
      </Stack>
    </Stack>
  );
};
