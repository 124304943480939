import { Center, Main as Layout } from "@operata/adagio";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import React from "react";
import ConfigEditor from "./ConfigEditor";
import EventBridge from "./EventBridge/EventBridge";
import GSMConfigs from "./GSM/GSMConfigs";
import { CONFIG_MODULES } from "./types/config";
import styled from "@emotion/styled";

const CUSTOM_COMPONENTS = {
  EVENTBRIDGE_MODULE: EventBridge,
  GSM_MODULE: GSMConfigs,
};

const ScrollRegion = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 15vh;

  .card {
    margin-bottom: 30px;
  }

  .textfield {
    margin-bottom: 30px;
  }
`;

const PadCenter = styled.div`
  padding: 20px;
`;

export const ConfigScroll = () => {
  const { path } = useRouteMatch();

  return (
    <ScrollRegion>
      <Layout.Scroll>
        <Center width="medium">
          <PadCenter>
            <Switch>
              {Object.entries(CONFIG_MODULES).map(([key, config]) => {
                if (config.isCustomModule) {
                  const CustomComponent =
                    CUSTOM_COMPONENTS[key as keyof typeof CUSTOM_COMPONENTS];
                  return (
                    <Route key={key} path={`${path}${config.path}`}>
                      <CustomComponent />
                    </Route>
                  );
                }

                return (
                  <Route
                    key={key}
                    path={`${path}${config.path}`}
                    exact={config.path === "/"}
                  >
                    <ConfigEditor fields={config.configFields || []} />
                  </Route>
                );
              })}
            </Switch>
          </PadCenter>
        </Center>
      </Layout.Scroll>
    </ScrollRegion>
  );
};
