interface PaginateProps {
  pageSize: number;
  page: number;
}

export function Paginate({ pageSize, page }: PaginateProps) {
  return function <T>(array: T[]): T[] {
    const startIndex = pageSize * (page - 1);
    const endIndex = pageSize * page;
    return array.slice(startIndex, endIndex);
  };
}
