import {
  GROUP_RECEIVED,
  GROUP_CREATED,
  GROUP_UPDATED,
  ERROR_GROUP_CREATED,
  GROUP_CONFIG_RECEIVED,
  ERROR_GROUP_CONFIG_RECEIVED,
  CLEAR_STATE,
} from "../actions/groups";

export type GroupActionTypes =
  | { type: typeof GROUP_RECEIVED; group: Group }
  | { type: typeof GROUP_CREATED; group: Group }
  | { type: typeof ERROR_GROUP_CREATED; message: string }
  | { type: typeof GROUP_UPDATED; group: Group }
  | { type: typeof GROUP_CONFIG_RECEIVED; config: GroupConfig }
  | { type: typeof ERROR_GROUP_CONFIG_RECEIVED }
  | { type: typeof CLEAR_STATE };

interface Group {
  id: string;
  customerName: string;
  customerID: string;
  name: string;
  region: string;
  status: string;
}

interface GroupConfig {
  [key: string]: any;
}

interface GroupState {
  loading: boolean;
  status?: string;
  message?: string;
  data?: Group;
  config?: GroupConfig;
}

const initialState: GroupState = { loading: false };

const groups = (
  state: GroupState = initialState,
  action: GroupActionTypes
): GroupState => {
  switch (action.type) {
    case GROUP_RECEIVED:
      return {
        ...state,
        data: action.group,
        loading: false,
        status: GROUP_RECEIVED,
      };

    case GROUP_CREATED:
      return {
        ...state,
        data: action.group,
        loading: false,
        status: GROUP_CREATED,
      };

    case ERROR_GROUP_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_GROUP_CREATED,
        message: action.message,
      };

    case GROUP_UPDATED:
      return {
        ...state,
        data: action.group,
        loading: false,
        status: GROUP_UPDATED,
      };

    case GROUP_CONFIG_RECEIVED:
      return {
        ...state,
        config: action.config,
        loading: false,
        status: GROUP_CONFIG_RECEIVED,
      };

    case ERROR_GROUP_CONFIG_RECEIVED:
      return {
        ...state,
        loading: false,
        status: ERROR_GROUP_CONFIG_RECEIVED,
      };

    case CLEAR_STATE:
      return {
        ...state,
        loading: false,
        status: "",
        message: "",
        data: {
          id: "",
          customerName: "",
          customerID: "",
          name: "",
          region: "",
          status: "",
        },
      };

    default:
      return { ...state };
  }
};

export default groups;
