import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TopInsights from "./TopInsights";
import _ from "lodash";

function JobDetails(props) {
  const { job, tags } = props;

  return (
    <div className="campaigns__grid">
      <List>
        <ListItem>
          <ListItemText
            primary="Agent Type"
            secondary={
              job
                ? job.agentType === "HUMAN_AGENT"
                  ? "Human"
                  : "Virtual"
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Start"
            secondary={
              job && job.createdOn
                ? moment(job.createdOn).format("HH:mm:ss")
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="End"
            secondary={
              job && job.updatedOn
                ? moment(job.updatedOn).format("HH:mm:ss")
                : "N/A"
            }
          />
        </ListItem>
      </List>
      <List>
        <ListItem>
          <ListItemText
            primary="Date"
            secondary={
              job && job.createdOn
                ? moment(job.createdOn).format("DD-MM-YYYY")
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Heartbeat Id"
            secondary={
              job && _.has(job, "heartbeatId") ? job.heartbeatId.trim() : "N/A"
            }
          />
        </ListItem>
      </List>

      <List>
        <ListItem>
          <ListItemText primary={"Top Insights"} />
        </ListItem>
        <TopInsights tags={tags} />
      </List>
    </div>
  );
}

JobDetails.propTypes = {
  job: PropTypes.object,
  tags: PropTypes.array,
};

export default JobDetails;
