import { AnyAction, combineReducers, Reducer } from "redux";
import auth, { AuthState } from "./auth";
import dashboard from "./dashboard";
import users, { UsersState } from "./users";
import eventBridge from "./eventBridge";
import user from "./user";
import groups, { GroupState } from "./groups";
import group from "./group";
import progress, { ProgressState } from "./progress";
import dialog from "./dialog";
import snackbar from "./snackbar";
import breadcrumb from "./breadcrumb";
import workflow from "./workflow";
import campaign from "./campaign";
import performance from "./performance";
import deployment from "./deployment";
import callstats, { CallStatsState } from "./callstats";
import agent, { AgentState } from "./agent";
import gsm from "./gsm";
import config from "./config";
import window from "./window";
import page from "./page";
// @ts-ignore
import { reducer as formReducer } from "redux-form";
import { ignoreActions } from "redux-ignore";
import gsmconfigs from "./gsmconfigs";
import heartbeat from "./heartbeat";
import audit from "./audit";
import location from "./location";
import reports from "./reports";
import appUsage from "./appUsage";
import { ACCESS_TOKEN_CHANGED } from "../actions/auth";
import extensionCoverage from "./extensionCoverage";
import homepage from "./homepage";
import canvas from "./canvas";
import customers from "./customers";
import playbooks, { PlaybooksState } from "./playbooks";
import billing, { BillingState } from "./billing";

function authIgnore(action: AnyAction) {
  return (
    !action.type ||
    !(
      action.type.indexOf(ACCESS_TOKEN_CHANGED) >= 0 ||
      action.type.indexOf("AUTH_SUCCESSFUL") >= 0 ||
      action.type.indexOf("AUTH_UNSUCCESSFUL") >= 0 ||
      action.type.indexOf("SWITCH_GROUP_SUCCESSFUL") >= 0 ||
      action.type.indexOf("SWITCH_GROUP_UNSUCCESSFUL") >= 0
    )
  );
}

export default combineReducers({
  customers: ignoreActions(customers, (action) => isReduxAction(action)),
  auth: ignoreActions(auth, authIgnore) as Reducer<AuthState>,
  dashboard: ignoreActions(dashboard, (action) => isReduxAction(action)),
  users: ignoreActions(users, (action) =>
    isReduxAction(action)
  ) as Reducer<UsersState>,
  eventBridge: ignoreActions(eventBridge, (action) => isReduxAction(action)),
  groups: ignoreActions(groups, (action) =>
    isReduxAction(action)
  ) as Reducer<GroupState>,
  user: ignoreActions(user, (action) => isReduxAction(action)),
  group: ignoreActions(group, (action) => isReduxAction(action)),
  progress: ignoreActions(progress, (action) =>
    isReduxAction(action)
  ) as Reducer<ProgressState>,
  dialog: ignoreActions(dialog, (action) => isReduxAction(action)),
  snackbar: ignoreActions(snackbar, (action) => isReduxAction(action)),
  window: ignoreActions(window, (action) => isReduxAction(action)),
  workflow: ignoreActions(
    workflow,
    (action) =>
      action.type.indexOf("@@") >= 0 || action.type.indexOf("WORKFLOW") < 0
  ),
  campaign: ignoreActions(
    campaign,
    (action) =>
      action.type.indexOf("@@") >= 0 || action.type.indexOf("CAMPAIGN") < 0
  ),
  breadcrumb: ignoreActions(breadcrumb, (action) => isReduxAction(action)),
  performance: ignoreActions(performance, (action) => isReduxAction(action)),
  deployment: ignoreActions(deployment, (action) => isReduxAction(action)),
  callstats: ignoreActions(callstats, (action) =>
    isReduxAction(action)
  ) as Reducer<CallStatsState>,
  agent: ignoreActions(agent, (action) =>
    isReduxAction(action)
  ) as Reducer<AgentState>,
  config: ignoreActions(config, (action) => isReduxAction(action)),
  gsm: ignoreActions(gsm, (action) => isReduxAction(action)),
  gsmconfigs: ignoreActions(gsmconfigs, (action) => isReduxAction(action)),
  heartbeat: ignoreActions(heartbeat, (action) => isReduxAction(action)),
  audit: ignoreActions(audit, (action) => isReduxAction(action)),
  location: ignoreActions(location, (action) => isReduxAction(action)),
  reports: ignoreActions(reports, (action) => isReduxAction(action)),
  appUsage: ignoreActions(appUsage, (action) => isReduxAction(action)),
  canvas: ignoreActions(canvas, (action) => isReduxAction(action)),
  extensionCoverage: ignoreActions(extensionCoverage, (action) =>
    isReduxAction(action)
  ),
  page: ignoreActions(page, (action) => isReduxAction(action)),
  homepage: ignoreActions(homepage, (action) => isReduxAction(action)),
  playbooks: ignoreActions(playbooks, (action) =>
    isReduxAction(action)
  ) as Reducer<PlaybooksState>,
  billing: ignoreActions(billing, (action) =>
    isReduxAction(action)
  ) as Reducer<BillingState>,
  form: formReducer,
});

function isReduxAction(action: AnyAction) {
  return action.type.indexOf("@@") >= 0;
}
