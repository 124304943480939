import {
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const niceConfig: ConfigField[] = [
  {
    type: `json`,
    key: `NICE`,
    title: `NiCE Softphone Configuration`,
    description: `NiCE Softphone Configuration`,
    fields: [
      { type: `string`, key: `CCAAS_ID`, title: "Tenant Id", default: `` },
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
  },
];
