// Action Types
export const SHOW_PROGRESS = "SHOW_PROGRESS";
export const DIALOG_CLOSED = "DIALOG_CLOSED";
export const SNACKBAR_CLOSED = "SNACKBAR_CLOSED";
export const PATH_CHANGED = "PATH_CHANGED";
export const BREADCRUMB_CHANGE_PATH = "BREADCRUMB_CHANGE_PATH";
export const WINDOW_RESIZED = "WINDOW_RESIZED";

export interface ProgressState {
  loading: boolean;
}

export type ProgressSlice = {
  progress: ProgressState;
};

interface ShowProgressAction {
  type: typeof SHOW_PROGRESS;
}

interface DialogClosedAction {
  type: typeof DIALOG_CLOSED;
}

interface SnackbarClosedAction {
  type: typeof SNACKBAR_CLOSED;
}

interface PathChangedAction {
  type: typeof PATH_CHANGED;
}

interface BreadcrumbChangePathAction {
  type: typeof BREADCRUMB_CHANGE_PATH;
}

interface WindowResizedAction {
  type: typeof WINDOW_RESIZED;
}

export type ProgressActionTypes =
  | ShowProgressAction
  | DialogClosedAction
  | SnackbarClosedAction
  | PathChangedAction
  | BreadcrumbChangePathAction
  | WindowResizedAction;

const progress = (
  state: ProgressState = { loading: false },
  action: ProgressActionTypes
): ProgressState => {
  if (action.type === SHOW_PROGRESS) {
    const returnState: ProgressState = { ...state, loading: true };
    return returnState;
  } else if (
    action.type &&
    action.type.indexOf("@@") < 0 &&
    action.type !== DIALOG_CLOSED &&
    action.type !== SNACKBAR_CLOSED &&
    action.type !== PATH_CHANGED &&
    action.type !== BREADCRUMB_CHANGE_PATH &&
    action.type !== WINDOW_RESIZED
  ) {
    return { ...state, loading: false };
  } else {
    return state;
  }
};

export default progress;
